import React from "react";

const UserLogo = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M14.6488 13.125C13.7919 11.621 12.4538 10.4492 10.85 9.79811C11.6476 9.19994 12.2367 8.366 12.534 7.4144C12.8312 6.46281 12.8215 5.44181 12.5063 4.49603C12.191 3.55024 11.5862 2.72763 10.7774 2.14471C9.96864 1.56179 8.99697 1.24811 8.00003 1.24811C7.00309 1.24811 6.03141 1.56179 5.22265 2.14471C4.41389 2.72763 3.80904 3.55024 3.49378 4.49603C3.17852 5.44181 3.16883 6.46281 3.46609 7.4144C3.76334 8.366 4.35247 9.19994 5.15003 9.79811C3.54623 10.4492 2.20818 11.621 1.35128 13.125C1.29818 13.2104 1.26279 13.3055 1.24722 13.4049C1.23164 13.5042 1.23619 13.6056 1.2606 13.7032C1.28502 13.8007 1.32879 13.8923 1.38932 13.9726C1.44985 14.0529 1.52591 14.1202 1.61296 14.1705C1.70002 14.2208 1.7963 14.253 1.89608 14.2654C1.99586 14.2777 2.0971 14.2699 2.19379 14.2423C2.29048 14.2148 2.38064 14.168 2.45891 14.1049C2.53718 14.0418 2.60196 13.9636 2.6494 13.875C3.7819 11.9175 5.7819 10.75 8.00003 10.75C10.2182 10.75 12.2182 11.9181 13.3507 13.875C13.4535 14.0403 13.6166 14.1592 13.8054 14.2065C13.9943 14.2537 14.1941 14.2257 14.3627 14.1283C14.5313 14.0309 14.6554 13.8718 14.7088 13.6845C14.7622 13.4973 14.7407 13.2966 14.6488 13.125ZM4.75003 5.99998C4.75003 5.35719 4.94064 4.72884 5.29775 4.19438C5.65487 3.65992 6.16245 3.24336 6.75631 2.99737C7.35017 2.75139 8.00363 2.68703 8.63407 2.81243C9.26451 2.93783 9.8436 3.24736 10.2981 3.70189C10.7526 4.15641 11.0622 4.7355 11.1876 5.36594C11.313 5.99638 11.2486 6.64984 11.0026 7.2437C10.7567 7.83756 10.3401 8.34514 9.80563 8.70226C9.27117 9.05937 8.64282 9.24998 8.00003 9.24998C7.13838 9.24899 6.3123 8.90626 5.70303 8.29698C5.09375 7.68771 4.75102 6.86163 4.75003 5.99998Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default UserLogo;
