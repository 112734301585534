import React, { useState } from "react";
import { Button, Dropdown, message, Space, Tooltip, Input, Table } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import { LandingPageTable } from "../../types/index";
import STLYUpArrow from "../../assets/image/STLYUpArrow";
import { useNavigate } from "react-router-dom";

const columns: ColumnsType<LandingPageTable> = [
  {
    title: "Hotel",
    // dataIndex: ["brandHotelId", "hotelName"],
    render: (record) => (
      <span className="text-[#004a98] font-medium font-roboto text-sm letter-spacing cursor-pointer">
        {record?.brandHotelId} {record?.brandHotelId && "|"} {record?.hotelName}
      </span>
    ),
  },
  {
    title: "Revenue on the books",
    // dataIndex: "revOTB",
    // defaultSortOrder: "descend",
    render: (record) => (
      <>
        <p className="font-roboto letter-spacing">${record.revOTB}</p>
        <p className="flex items-center justify-items-start font-roboto letter-spacing">
          <span className="text-green-900 font-roboto">
            +{record.revOTBVarSTLY}%
          </span>{" "}
          &nbsp; <STLYUpArrow /> &nbsp; STLY
        </p>
      </>
    ),
    sorter: (a, b) => a.revOTB - b.revOTB,
  },
  {
    title: "Occupancy on the books",
    // dataIndex: "otbRooms",
    // defaultSortOrder: "descend",
    render: (record: any) => (
      <>
        <p className="font-roboto letter-spacing">{record.otbRooms}</p>
        <p className="flex items-center justify-items-start font-roboto letter-spacing">
          <span className="text-green-900 font-roboto">
            +{record.otbRoomsVarSTLY}%
          </span>
          &nbsp;
          <STLYUpArrow />
          &nbsp; STLY
        </p>
      </>
    ),
  },
  {
    title: "ADR on the books",
    // dataIndex: "adrOTB",
    // defaultSortOrder: "descend",
    render: (record: any) => (
      <>
        <p className="font-roboto letter-spacing">${record.adrOTB}</p>
        <p className="flex items-center justify-items-start font-roboto letter-spacing">
          <span className="text-green-900 font-roboto">
            +{record.adrOTBVarSTLY}%
          </span>{" "}
          &nbsp;
          <STLYUpArrow />
          &nbsp; STLY
        </p>
      </>
    ),
    sorter: (a, b) => a.adrOTB - b.adrOTB,
  },
];
const onChange: TableProps<LandingPageTable>["onChange"] = (
  pagination,
  filters,
  sorter,
  extra
) => {
  console.log("params", pagination, filters, sorter, extra);
};

const TableWithFilter = (props: any) => {
  const navigate = useNavigate();
  return (
    <Table
      columns={columns}
      dataSource={props?.data}
      onChange={onChange}
      loading={props?.isLoading}
      pagination={{
        position: ["bottomCenter"],
      }}
      onRow={(record) => ({
        onClick: (e) => navigate(`/summary/${record.aticaHotelId}`),
      })}
    />
  );
};
export default TableWithFilter;
