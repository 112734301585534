import React from "react";

const STLYUpArrow = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
      >
        <path
          d="M11.25 2.625V5.625C11.25 5.72446 11.2105 5.81984 11.1401 5.89016C11.0698 5.96049 10.9744 6 10.875 6C10.7755 6 10.6801 5.96049 10.6098 5.89016C10.5395 5.81984 10.5 5.72446 10.5 5.625V3.53016L6.64028 7.39031C6.60545 7.42518 6.56409 7.45284 6.51857 7.47171C6.47304 7.49058 6.42425 7.50029 6.37496 7.50029C6.32568 7.50029 6.27689 7.49058 6.23136 7.47171C6.18584 7.45284 6.14448 7.42518 6.10965 7.39031L4.49996 5.78016L1.39028 8.89031C1.31991 8.96068 1.22448 9.00021 1.12496 9.00021C1.02545 9.00021 0.930017 8.96068 0.859652 8.89031C0.789287 8.81995 0.749756 8.72451 0.749756 8.625C0.749756 8.52549 0.789287 8.43005 0.859652 8.35969L4.23465 4.98469C4.26948 4.94982 4.31084 4.92216 4.35636 4.90329C4.40189 4.88442 4.45068 4.8747 4.49996 4.8747C4.54925 4.8747 4.59804 4.88442 4.64357 4.90329C4.68909 4.92216 4.73045 4.94982 4.76528 4.98469L6.37496 6.59484L9.96981 3H7.87497C7.77551 3 7.68013 2.96049 7.6098 2.89016C7.53947 2.81984 7.49997 2.72446 7.49997 2.625C7.49997 2.52554 7.53947 2.43016 7.6098 2.35984C7.68013 2.28951 7.77551 2.25 7.87497 2.25H10.875C10.9744 2.25 11.0698 2.28951 11.1401 2.35984C11.2105 2.43016 11.25 2.52554 11.25 2.625Z"
          fill="#367D40"
        />
      </svg>
    </>
  );
};

export default STLYUpArrow;
