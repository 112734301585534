import React, { useEffect } from "react";
import Header from "../../components/common/Header";
import LandingPageComp from "../../components/LandingPage/LandingPageComp";
const LandingPage = () => {
  return (
    <div className="w-full h-screen bg-white">
      <Header />
      <LandingPageComp />
    </div>
  );
};

export default LandingPage;
