import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "../pages/LandingPage/LandingPage";
import Summary from "../pages/Summary/Summary";
import PreLanding from "../pages/PreLanding/PreLandingPage";
import { useAuth0 } from "@auth0/auth0-react";

const Router: React.FC = () => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  let token = localStorage.getItem("token");
  return (
    <>
      {/* This Logic Has To Change */}
      <Routes>
        <Route path="/summary/:hotel_id" element={<Summary />} />
        <Route path="/dashboard" element={<LandingPage />} />
        <Route path="/" element={<PreLanding />} />
        <Route path="/login" element={<PreLanding />} />
      </Routes>
    </>
  );
};

export default Router;
