import React from "react";
import CopyIcon from "../../assets/image/CopyIcon";

const PreLandingPageFooter = () => {
  return (
    <>
      <div className="h-20 px-12 flex flex-wrap items-center justify-start">
        <h3 className="text-gray-400 text-xs font-normal font-poppins flex">
          Copyrights&nbsp;
          <span>
            <CopyIcon />
          </span>
          &nbsp;Atica Global Inc / Atica Hospitality Pvt Ltd
        </h3>
      </div>
    </>
  );
};

export default PreLandingPageFooter;
