import React from "react";

const Logo = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="68"
        height="28"
        viewBox="0 0 68 28"
        fill="none"
      >
        <path
          d="M4.38513 21.4236H2.39648L8.68351 6.61237H10.8068L17.1249 21.4236H15.0016L13.0855 16.7627H8.18634L8.71459 15.2091H12.4433L9.67784 8.49743L4.38513 21.4236ZM29.0759 6.61237V8.16599H24.4152V21.4236H22.4989V8.16599H17.8381V6.61237H29.0759ZM33.5282 6.61237V21.4236H31.6121V6.61237H33.5282ZM48.4934 20.9057C47.216 21.32 45.8349 21.5271 44.3504 21.5271C39.1717 21.5271 36.5823 18.955 36.5823 13.8108C36.5823 8.94279 39.1717 6.50879 44.3504 6.50879C45.8349 6.50879 47.216 6.71595 48.4934 7.13024V8.78744C47.216 8.30408 45.904 8.06241 44.5575 8.06241C40.518 8.06241 38.4984 9.97854 38.4984 13.8108C38.4984 17.9193 40.518 19.9735 44.5575 19.9735C45.904 19.9735 47.216 19.7318 48.4934 19.2485V20.9057ZM52.0859 21.4236H50.0973L56.3843 6.61237H58.5077L64.8257 21.4236H62.7024L60.7863 16.7627H55.8871L56.4153 15.2091H60.1442L57.3786 8.49743L52.0859 21.4236Z"
          fill="#004A98"
        />
      </svg>
    </>
  );
};

export default Logo;
