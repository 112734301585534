import React from "react";
import PreLandingPageHeader from "../../components/common/PreLandingPageHeader";
import PreLandingPageComp from "../../components/PreLanding/PreLandingPageComp";
const PreLanding = () => {
  return (
    <div className="w-full h-screen bg-white">
      <PreLandingPageHeader />
      <PreLandingPageComp />
    </div>
  );
};

export default PreLanding;
