import React from "react";

const ScrollLogo6 = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="136"
        height="33"
        viewBox="0 0 136 33"
        fill="none"
      >
        <g clip-path="url(#clip0_788_236495)">
          <mask
            id="mask0_788_236495"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="-40"
            y="-84"
            width="205"
            height="201"
          >
            <path
              d="M-39.416 116.169H164.579V-83.3118H-39.416V116.169Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_788_236495)">
            <path
              d="M70.0747 18.0547L64.9529 5.20249L59.8607 17.9693C61.1856 17.9277 62.5258 17.9036 63.8956 17.9036C66.0101 17.9036 68.0676 17.9551 70.0747 18.0547ZM112.582 31.0853C101.606 26.3218 89.2623 22.7941 74.7578 21.6032L79.1744 32.3079H75.7558L71.3963 21.3722C68.9604 21.2376 66.462 21.1621 63.9011 21.1621C62.0849 21.1621 60.3016 21.1993 58.5468 21.2704L54.1455 32.3079H50.7248L55.2028 21.4478C39.8176 22.4547 26.7148 26.093 15.2055 31.082V30.4077C22.8871 26.1291 30.4832 22.9353 38.6836 20.849V14.2785L29.4479 0.129265H32.8796L40.2081 11.8858L47.5398 0.129265H50.9671L41.7336 14.2785L41.7314 20.1288C46.4244 19.112 51.3302 18.4399 56.5759 18.1214L64.1873 -0.339199H65.7129L73.3847 18.2681C78.5339 18.6786 83.3607 19.4349 87.9879 20.5415V2.5909H80.4422V0.129265H98.6548V2.5909H91.1125V21.3503C98.5528 23.4245 105.534 26.4411 112.582 30.4143V31.0853ZM124.785 0.129265H106.574V2.5909H114.119V24.7774H117.241V2.5909H124.785V0.129265ZM20.244 24.7774L17.1215 24.7807V12.618H3.65564V24.7774H0.533203V0.129265H3.65564V10.1586H17.1215V0.129265H20.244V24.7774Z"
              fill="#52565B"
            />
            <path
              d="M133.002 1.91973C132.852 1.83217 132.611 1.78948 132.28 1.78948H131.771V3.02304H132.309C132.565 3.02304 132.751 2.99567 132.88 2.94642C133.111 2.849 133.232 2.67388 133.232 2.41556C133.232 2.16929 133.153 2.00511 133.002 1.91973ZM132.359 1.38669C132.777 1.38669 133.086 1.42719 133.284 1.506C133.634 1.65266 133.809 1.94163 133.809 2.36631C133.809 2.66731 133.699 2.89169 133.48 3.03617C133.361 3.1117 133.201 3.16314 132.987 3.19707C133.258 3.23866 133.449 3.34921 133.574 3.52872C133.692 3.70822 133.755 3.88445 133.755 4.05629V4.30256C133.755 4.38247 133.758 4.46675 133.764 4.55321C133.769 4.64297 133.778 4.70098 133.793 4.73272L133.814 4.77431H133.258C133.255 4.76227 133.252 4.75242 133.248 4.74148C133.245 4.72944 133.245 4.71411 133.242 4.70098L133.227 4.59152V4.32227C133.227 3.93261 133.117 3.67429 132.906 3.54842C132.777 3.47727 132.56 3.43897 132.24 3.43897H131.771V4.77431H131.17V1.38669H132.359ZM130.54 1.21156C130.022 1.73147 129.765 2.35974 129.765 3.09309C129.765 3.83191 130.021 4.46237 130.536 4.98337C131.053 5.50657 131.679 5.76488 132.417 5.76488C133.154 5.76488 133.781 5.50657 134.301 4.98337C134.816 4.46237 135.074 3.83191 135.074 3.09309C135.074 2.35974 134.816 1.73147 134.301 1.21156C133.778 0.693841 133.151 0.433339 132.417 0.433339C131.683 0.433339 131.06 0.693841 130.54 1.21156ZM134.604 5.27671C134.003 5.88418 133.275 6.18628 132.417 6.18628C131.56 6.18628 130.832 5.88418 130.229 5.27671C129.632 4.67143 129.334 3.94465 129.334 3.08981C129.334 2.24153 129.636 1.51475 130.24 0.912751C130.841 0.311844 131.567 0.0108433 132.417 0.0108433C133.275 0.0108433 134.003 0.311844 134.604 0.912751C135.21 1.50928 135.512 2.23825 135.512 3.08981C135.512 3.94136 135.21 4.67033 134.604 5.27671Z"
              fill="#52565B"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_788_236495">
            <rect
              width="135"
              height="32.6471"
              fill="white"
              transform="translate(0.53125)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ScrollLogo6;
