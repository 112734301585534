import React from "react";

const UpArrow = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20.0306 15.5306C19.961 15.6003 19.8782 15.6557 19.7872 15.6934C19.6961 15.7311 19.5986 15.7506 19.5 15.7506C19.4014 15.7506 19.3038 15.7311 19.2128 15.6934C19.1217 15.6557 19.039 15.6003 18.9694 15.5306L12 8.56029L5.03061 15.5306C4.88988 15.6713 4.69901 15.7504 4.49999 15.7504C4.30097 15.7504 4.1101 15.6713 3.96936 15.5306C3.82863 15.3899 3.74957 15.199 3.74957 15C3.74957 14.801 3.82863 14.6101 3.96936 14.4694L11.4694 6.96935C11.539 6.89962 11.6217 6.8443 11.7128 6.80656C11.8038 6.76882 11.9014 6.74939 12 6.74939C12.0986 6.74939 12.1961 6.76882 12.2872 6.80656C12.3782 6.8443 12.461 6.89962 12.5306 6.96935L20.0306 14.4694C20.1003 14.539 20.1557 14.6217 20.1934 14.7128C20.2312 14.8038 20.2506 14.9014 20.2506 15C20.2506 15.0985 20.2312 15.1961 20.1934 15.2872C20.1557 15.3782 20.1003 15.4609 20.0306 15.5306Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default UpArrow;
