import React from "react";

const ScrollLogo2 = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="218"
        height="44"
        viewBox="0 0 218 44"
        fill="none"
      >
        <g clip-path="url(#clip0_690_230214)">
          <path
            d="M182.61 14.7679C180.641 14.7679 180.001 16.186 179.853 17.9694L184.846 17.2117C184.846 15.8936 184.134 14.7679 182.61 14.7679ZM187.583 23.3504C187.636 23.312 187.715 23.3056 187.771 23.344C187.804 23.3671 187.823 23.4056 187.831 23.4633C187.849 23.5966 187.849 23.7287 187.849 23.8607C187.849 26.1352 185.642 26.5993 183.839 26.5993C179.583 26.5993 176.385 23.812 176.385 19.4605C176.385 15.477 178.894 12.6909 182.929 12.6909C186.126 12.6909 188.34 14.6705 188.34 17.8476C188.34 18.1912 188.317 18.5079 188.267 18.8502L179.905 19.9977C180.199 22.444 181.863 24.1056 184.65 24.1056C185.614 24.1056 186.774 23.9441 187.583 23.3504Z"
            fill="#52565B"
          />
          <path
            d="M92.5201 14.7679C90.5756 14.7679 89.9344 16.1847 89.7886 17.9694L94.7819 17.2104C94.7819 15.8924 94.0684 14.7679 92.5201 14.7679ZM97.494 23.3504C97.5521 23.312 97.6437 23.3043 97.7069 23.3428C97.7392 23.3671 97.7573 23.403 97.7637 23.462C97.7831 23.5953 97.7831 23.7274 97.7831 23.8595C97.7831 26.1326 95.569 26.5993 93.7742 26.5993C89.4944 26.5993 86.3203 23.8107 86.3203 19.4605C86.3203 15.4757 88.8054 12.6909 92.84 12.6909C96.0373 12.6909 98.2747 14.6692 98.2747 17.8476C98.2747 18.1899 98.2501 18.5079 98.2024 18.8489L89.8389 19.9977C90.1331 22.4427 91.7807 24.1043 94.5845 24.1043C95.5406 24.1043 96.685 23.9441 97.494 23.3504Z"
            fill="#52565B"
          />
          <path
            d="M176.609 23.7877C176.638 23.9005 176.652 24.1262 176.652 24.2736C176.652 26.0096 174.782 26.5968 173.357 26.5968C170.576 26.5968 168.832 24.9583 168.832 22.1235V15.5001H167.311V13.0794H168.832V10.4883H172.496V13.0794H176.109V15.5001H172.519V22.0017C172.519 23.371 173.331 24.1775 174.758 24.1775C175.337 24.1775 175.964 24.0236 176.382 23.6992C176.434 23.6595 176.493 23.6518 176.544 23.6787C176.584 23.7018 176.604 23.7454 176.609 23.7877Z"
            fill="#52565B"
          />
          <path
            d="M118.499 23.7889C118.525 23.9017 118.539 24.126 118.539 24.2748C118.539 26.0108 116.645 26.5993 115.242 26.5993C112.437 26.5993 110.716 24.9594 110.716 22.1247V15.5012H109.172V13.0806H110.716V10.4907H114.383V13.0806H117.997V15.5012H114.405V22.0016C114.405 23.3709 115.218 24.1773 116.62 24.1773C117.201 24.1773 117.847 24.0248 118.269 23.7004C118.32 23.6606 118.38 23.6529 118.429 23.6799C118.472 23.7017 118.491 23.7465 118.499 23.7889Z"
            fill="#52565B"
          />
          <path
            d="M188.687 14.8321C188.86 13.5487 189.973 12.8858 191.187 12.8858C192.639 12.8858 193.657 13.7666 193.657 15.2821V15.7706H193.733C194.83 14.2064 195.888 12.6909 197.872 12.6909C198.072 12.6909 198.298 12.714 198.519 12.7627L198.321 16.2847C197.947 16.1603 197.566 16.1116 197.166 16.1116C195.863 16.1116 194.83 17.1873 193.862 18.1425V26.2083H190.205V15.9898C190.205 15.4526 190.029 14.841 189.369 14.841C189.194 14.841 189.021 14.8808 188.86 14.9564C188.818 14.9782 188.767 14.9757 188.729 14.9423C188.694 14.9141 188.681 14.8756 188.687 14.8321Z"
            fill="#52565B"
          />
          <path
            d="M212.609 24.3031C212.646 24.28 212.699 24.2774 212.737 24.3338C212.758 24.3543 212.775 24.38 212.769 24.4415C212.651 25.5224 211.768 26.4019 210.288 26.4019C208.566 26.4019 207.632 25.3044 207.632 23.4697V17.3591C207.632 16.4052 207.04 15.9411 206.326 15.9411C204.997 15.9411 203.844 17.2373 203.08 18.0181V26.2083H199.393V13.0807H203.007V15.5757H203.08C204.555 13.9859 206.154 12.6909 208.272 12.6909C208.983 12.6909 209.757 12.9345 210.312 13.4012C211.222 14.1564 211.321 15.3064 211.321 16.4296V23.2748C211.321 23.9107 211.517 24.4479 212.108 24.4479C212.309 24.4479 212.471 24.4069 212.609 24.3031Z"
            fill="#52565B"
          />
          <path
            d="M157.435 23.4711C158.314 23.9903 159.404 24.2519 160.516 24.2519C161.734 24.2519 162.706 23.8378 162.706 22.8095C162.706 21.93 161.836 21.5133 160.565 20.903C158.841 20.0222 157.391 19.1183 157.391 16.8951C157.391 14.8899 159.089 12.7397 162.974 12.7397C164.034 12.7397 164.991 12.8372 165.871 12.9833L165.437 15.4771C164.747 15.2565 163.836 15.1104 163 15.1104C161.657 15.1104 160.934 15.6489 160.934 16.4053C160.934 17.2118 161.915 17.7015 163.147 18.3131C164.795 19.1183 166.395 20.0478 166.395 22.2967C166.395 24.0801 165.018 26.5994 160.908 26.5994C158.549 26.5994 157.172 25.7186 157.172 23.9826C157.172 23.8506 157.172 23.7172 157.19 23.5826C157.196 23.5326 157.223 23.4801 157.275 23.4583C157.327 23.4326 157.389 23.4454 157.435 23.4711Z"
            fill="#52565B"
          />
          <path
            d="M99.3179 23.4711C100.175 23.9903 101.261 24.2506 102.399 24.2506C103.605 24.2506 104.563 23.8365 104.563 22.8095C104.563 21.9287 103.701 21.5133 102.448 20.903C100.702 20.0222 99.274 19.1183 99.274 16.8938C99.274 14.8899 100.948 12.7397 104.835 12.7397C105.916 12.7397 106.876 12.8359 107.737 12.9833L107.318 15.4758C106.63 15.2565 105.718 15.1091 104.858 15.1091C103.531 15.1091 102.817 15.6476 102.817 16.4053C102.817 17.2105 103.777 17.7015 105.005 18.3131C106.679 19.1183 108.252 20.0465 108.252 22.2954C108.252 24.0801 106.9 26.5994 102.793 26.5994C100.407 26.5994 99.0547 25.7186 99.0547 23.9826C99.0547 23.8506 99.0547 23.7147 99.0715 23.5813C99.0792 23.5326 99.1076 23.4801 99.1566 23.457C99.2095 23.4326 99.274 23.4454 99.3179 23.4711Z"
            fill="#52565B"
          />
          <path
            d="M150.662 14.7679C148.694 14.7679 148.055 16.186 147.907 17.9694L152.899 17.2117C152.899 15.8936 152.187 14.7679 150.662 14.7679ZM155.636 23.3504C155.689 23.312 155.767 23.3056 155.824 23.344C155.855 23.3671 155.876 23.4056 155.882 23.4633C155.902 23.5966 155.902 23.7287 155.902 23.8607C155.902 26.1352 153.699 26.5993 151.891 26.5993C147.637 26.5993 144.438 23.812 144.438 19.4605C144.438 15.477 146.947 12.6909 150.982 12.6909C154.178 12.6909 156.392 14.6705 156.392 17.8476C156.392 18.1912 156.367 18.5079 156.32 18.8502L147.956 19.9977C148.252 22.444 149.922 24.1056 152.702 24.1056C153.669 24.1056 154.827 23.9441 155.636 23.3504Z"
            fill="#52565B"
          />
          <path
            d="M75.2416 11.0325V16.2866C77.128 16.1302 78.0596 15.761 78.8337 15.2558C79.6311 14.7378 80.0931 14.007 80.1369 13.1993C80.1576 12.7416 80.0182 12.2928 79.7292 11.9518C79.3886 11.5441 78.8466 11.2505 78.1215 11.0864C77.7048 10.9915 77.1654 10.9428 76.608 10.9428C76.1655 10.9428 75.6958 10.9748 75.2416 11.0325ZM75.2416 23.8845C75.6416 23.9627 76.2235 24.014 76.7306 24.014C78.1267 24.014 78.8157 23.7293 79.3524 23.4216C79.8711 23.1254 80.293 22.6677 80.5704 22.1023C80.8479 21.5356 80.9588 20.8971 80.8982 20.2304C80.7846 19.0393 79.9989 18.1778 78.6879 17.8047C78.266 17.6867 77.7589 17.6213 77.2003 17.6213C76.5693 17.6213 75.8945 17.7098 75.2416 17.8752V23.8845ZM76.3732 26.4538C74.7217 26.4538 72.9579 26.2948 71.3257 25.9987L71.2263 25.9807V9.21446L71.327 9.20036C73.6275 8.82854 75.4765 8.64648 77.0067 8.64648H77.0196C79.1241 8.64777 80.4246 8.87726 81.7098 9.46319C82.4491 9.8068 83.0129 10.2837 83.3897 10.8812C83.7058 11.3825 83.8761 11.9582 83.8839 12.5672C83.889 12.9288 83.8193 13.6544 83.2916 14.4506C82.8387 15.1366 82.1652 15.7033 81.2594 16.1366C81.862 16.2802 82.4439 16.5251 82.9174 16.7841C83.7897 17.261 84.4258 17.8918 84.8064 18.6611C85.1651 19.3906 85.2941 20.2407 85.1922 21.2164C84.9999 22.9985 83.8026 24.6024 81.9897 25.4653C80.8801 25.9807 79.626 26.2846 78.128 26.391C77.568 26.4333 76.9706 26.4538 76.3732 26.4538Z"
            fill="#52565B"
          />
          <path
            d="M138.457 11.2845C138.743 11.1499 138.971 11.1665 139.134 11.1896C140.031 11.3191 140.5 12.9102 140.404 15.5603C140.358 16.8373 140.093 18.7733 139.482 20.4709C139.186 21.2888 138.846 21.9312 138.471 22.3838C138.064 22.8812 137.608 23.1325 137.137 23.1377H137.075C136.966 23.1377 136.839 23.1338 136.729 23.1107L134.635 8.96769H130.496L132.053 18.3348C131.954 18.9849 131.712 20.2119 131.21 21.2927C130.719 22.3312 130.115 22.9184 129.391 23.0364C129.3 23.0505 129.111 23.0671 128.936 23.0671C128.858 23.0671 128.79 23.0646 128.732 23.0582L126.649 8.96769H122.508L125.375 26.2327L125.449 26.2519C125.989 26.3814 126.551 26.4494 127.1 26.4494C127.558 26.4494 128.002 26.4019 128.412 26.3122C129.444 26.0852 130.436 25.4788 131.238 24.5621C131.865 23.8172 132.36 22.9274 132.659 21.9863L133.365 26.2353L133.441 26.2532C134.208 26.4186 134.715 26.4519 135.293 26.4519C135.454 26.4519 135.622 26.4481 135.778 26.4455C136.826 26.4289 137.831 26.1417 138.72 25.5955C139.549 25.0826 140.284 24.3557 140.896 23.4056C141.775 22.1068 142.446 20.4927 142.899 18.5823C143.296 16.9309 143.505 15.1231 143.505 13.4025C143.505 11.9576 143.28 10.8268 142.836 10.0165C142.333 9.09975 141.552 8.63306 140.548 8.63306C140.01 8.63306 139.459 8.85999 139.041 9.2536C138.722 9.5549 138.28 10.1319 138.245 11.1434C138.242 11.1896 138.26 11.2422 138.302 11.2704C138.346 11.3011 138.409 11.3063 138.457 11.2845Z"
            fill="#52565B"
          />
          <path
            d="M95.8399 42.8083V38.4606H91.6943V42.8083H89.5215V33.3347H91.6943V36.9849H95.8399V33.3347H98.0269V42.8083H95.8399Z"
            fill="#52565B"
          />
          <path
            d="M103.24 36.7149C102.01 36.7149 101.754 37.9906 101.754 38.942C101.754 39.9087 101.956 41.7601 103.307 41.7601C104.51 41.7601 104.779 40.1907 104.779 39.1843C104.779 38.2047 104.535 36.7149 103.24 36.7149ZM103.172 43.0614C100.849 43.0614 99.6621 41.3177 99.6621 39.1445C99.6621 36.8624 101.12 35.4136 103.401 35.4136C105.588 35.4136 106.859 36.756 106.859 38.942C106.859 41.21 105.55 43.0614 103.172 43.0614Z"
            fill="#52565B"
          />
          <path
            d="M172.482 36.7149C171.252 36.7149 170.995 37.9906 170.995 38.942C170.995 39.9087 171.198 41.7601 172.549 41.7601C173.751 41.7601 174.021 40.1907 174.021 39.1843C174.021 38.2047 173.778 36.7149 172.482 36.7149ZM172.413 43.0614C170.091 43.0614 168.902 41.3177 168.902 39.1445C168.902 36.8624 170.362 35.4136 172.643 35.4136C174.83 35.4136 176.099 36.756 176.099 38.942C176.099 41.21 174.79 43.0614 172.413 43.0614Z"
            fill="#52565B"
          />
          <path
            d="M116.585 36.5673C115.517 36.5673 115.167 37.3443 115.087 38.311L117.813 37.8968C117.813 37.1853 117.422 36.5673 116.585 36.5673ZM119.302 41.2522C119.333 41.2304 119.376 41.2265 119.407 41.2496C119.425 41.2624 119.435 41.2829 119.438 41.3124C119.448 41.3868 119.448 41.4586 119.448 41.5329C119.448 42.7663 118.245 43.0215 117.26 43.0215C114.938 43.0215 113.195 41.5047 113.195 39.1302C113.195 36.9558 114.56 35.4275 116.76 35.4275C118.503 35.4275 119.718 36.5147 119.718 38.2443C119.718 38.4328 119.705 38.6071 119.678 38.7943L115.113 39.4264C115.275 40.7547 116.18 41.6663 117.706 41.6663C118.23 41.6663 118.858 41.5778 119.302 41.2522Z"
            fill="#52565B"
          />
          <path
            d="M158.906 36.5673C157.838 36.5673 157.487 37.3443 157.407 38.311L160.133 37.8968C160.133 37.184 159.743 36.5673 158.906 36.5673ZM161.624 41.2522C161.652 41.2304 161.696 41.2265 161.726 41.2483C161.744 41.2624 161.754 41.2816 161.758 41.3124C161.767 41.3868 161.767 41.4586 161.767 41.5317C161.767 42.7663 160.564 43.0215 159.58 43.0215C157.259 43.0215 155.516 41.5047 155.516 39.1302C155.516 36.9558 156.879 35.4275 159.082 35.4275C160.823 35.4275 162.038 36.5147 162.038 38.2443C162.038 38.4328 162.025 38.6071 161.998 38.7943L157.434 39.4264C157.596 40.7547 158.5 41.6663 160.025 41.6663C160.552 41.6663 161.179 41.5778 161.624 41.2522Z"
            fill="#52565B"
          />
          <path
            d="M112.821 41.4938C112.835 41.5567 112.844 41.6798 112.844 41.7618C112.844 42.7003 111.817 43.0221 111.045 43.0221C109.521 43.0221 108.577 42.1247 108.577 40.581V36.9705H107.742V35.641H108.577V34.2319H110.576V35.641H112.546V36.9705H110.589V40.513C110.589 41.2631 111.034 41.7067 111.804 41.7067C112.123 41.7067 112.465 41.6221 112.694 41.4438C112.723 41.4233 112.756 41.4195 112.783 41.4336C112.806 41.4451 112.817 41.4695 112.821 41.4938Z"
            fill="#52565B"
          />
          <path
            d="M188.17 41.4938C188.183 41.5567 188.194 41.6798 188.194 41.7618C188.194 42.7003 187.166 43.0221 186.395 43.0221C184.872 43.0221 183.925 42.1247 183.925 40.581V36.9705H183.092V35.641H183.925V34.2319H185.924V35.641H187.895V36.9705H185.937V40.513C185.937 41.2631 186.382 41.7067 187.154 41.7067C187.472 41.7067 187.814 41.6221 188.045 41.4438C188.072 41.4233 188.106 41.4195 188.133 41.4336C188.156 41.4451 188.166 41.4695 188.17 41.4938Z"
            fill="#52565B"
          />
          <path
            d="M176.997 36.6019C177.091 35.8955 177.693 35.5339 178.357 35.5339C179.154 35.5339 179.706 36.0173 179.706 36.8494V37.116H179.747C180.342 36.2583 180.923 35.4275 182.004 35.4275C182.111 35.4275 182.231 35.4416 182.354 35.4672L182.245 37.3981C182.044 37.3314 181.842 37.3045 181.626 37.3045C180.909 37.3045 180.342 37.8827 179.816 38.4058V42.8074H177.818V37.2366C177.818 36.943 177.723 36.607 177.371 36.607C177.275 36.607 177.18 36.6288 177.092 36.6711C177.07 36.684 177.04 36.6814 177.02 36.6635C177.002 36.6481 176.994 36.6263 176.997 36.6019Z"
            fill="#52565B"
          />
          <path
            d="M124.637 41.3187C125.106 41.6046 125.702 41.7482 126.315 41.7482C126.975 41.7482 127.501 41.52 127.501 40.9546C127.501 40.4725 127.03 40.2443 126.342 39.9096C125.395 39.4391 124.613 38.9429 124.613 37.7236C124.613 36.6364 125.531 35.4556 127.651 35.4556C128.23 35.4556 128.759 35.5081 129.23 35.5889L129.002 36.9569C128.624 36.8364 128.123 36.7569 127.665 36.7569C126.935 36.7569 126.542 37.0518 126.542 37.4544C126.542 37.898 127.07 38.166 127.746 38.5019C128.651 38.9429 129.514 39.4532 129.514 40.6738C129.514 41.6546 128.772 43.0226 126.53 43.0226C125.234 43.0226 124.492 42.5393 124.492 41.6008C124.492 41.5277 124.492 41.4533 124.501 41.3802C124.505 41.352 124.521 41.3238 124.548 41.3123C124.577 41.2982 124.612 41.3046 124.637 41.3187Z"
            fill="#52565B"
          />
          <path
            d="M163.023 41.3187C163.493 41.6046 164.09 41.7482 164.702 41.7482C165.363 41.7482 165.889 41.52 165.889 40.9546C165.889 40.4725 165.416 40.2443 164.729 39.9096C163.782 39.4391 163 38.9429 163 37.7236C163 36.6364 163.918 35.4556 166.039 35.4556C166.617 35.4556 167.145 35.5081 167.615 35.5889L167.387 36.9569C167.009 36.8364 166.51 36.7569 166.05 36.7569C165.321 36.7569 164.928 37.0518 164.928 37.4544C164.928 37.898 165.457 38.166 166.133 38.5019C167.036 38.9429 167.901 39.4532 167.901 40.6738C167.901 41.6546 167.159 43.0226 164.916 43.0226C163.62 43.0226 162.879 42.5393 162.879 41.6008C162.879 41.5277 162.879 41.4533 162.888 41.3802C162.891 41.352 162.907 41.3238 162.934 41.3123C162.964 41.2982 163 41.3046 163.023 41.3187Z"
            fill="#52565B"
          />
          <path
            d="M188.777 41.3187C189.246 41.6046 189.842 41.7482 190.455 41.7482C191.116 41.7482 191.642 41.52 191.642 40.9546C191.642 40.4725 191.169 40.2443 190.481 39.9096C189.535 39.4391 188.753 38.9429 188.753 37.7236C188.753 36.6364 189.671 35.4556 191.791 35.4556C192.37 35.4556 192.898 35.5081 193.369 35.5889L193.14 36.9569C192.762 36.8364 192.263 36.7569 191.804 36.7569C191.075 36.7569 190.681 37.0518 190.681 37.4544C190.681 37.898 191.209 38.166 191.886 38.5019C192.789 38.9429 193.653 39.4532 193.653 40.6738C193.653 41.6546 192.911 43.0226 190.669 43.0226C189.373 43.0226 188.631 42.5393 188.631 41.6008C188.631 41.5277 188.631 41.4533 188.641 41.3802C188.645 41.352 188.661 41.3238 188.688 41.3123C188.716 41.2982 188.752 41.3046 188.777 41.3187Z"
            fill="#52565B"
          />
          <path
            d="M149.953 34.5032C149.71 34.5032 149.467 34.5289 149.225 34.5699V37.5354C149.482 37.5611 149.724 37.5752 149.981 37.5752C150.994 37.5752 151.817 37.1059 151.817 36.0456C151.817 34.9327 150.98 34.5032 149.953 34.5032ZM155.279 41.7203C155.306 41.71 155.338 41.7126 155.36 41.7344C155.376 41.7485 155.382 41.7716 155.379 41.7985C155.314 42.587 154.604 42.9139 153.843 42.9139C151.885 42.9139 150.79 40.9036 150.088 38.7291C149.806 38.756 149.495 38.756 149.225 38.756V42.8088H147.051V33.3903C148.105 33.3352 149.144 33.2942 150.196 33.2942C151.938 33.2942 154.018 33.725 154.045 35.8584C154.045 37.1059 153.112 38.0047 152.045 38.4073C152.749 39.7086 154.031 41.7754 154.977 41.7754C155.085 41.7754 155.182 41.7536 155.279 41.7203Z"
            fill="#52565B"
          />
          <path
            d="M135.9 38.5928C135.603 38.9018 135.455 39.2915 135.455 39.721C135.455 40.8339 136.508 41.7596 137.682 41.7596C138.074 41.7596 138.478 41.666 138.803 41.4647C137.79 40.5647 136.779 39.6262 135.9 38.5928ZM139.451 37.6017C139.416 37.6132 139.392 37.6171 139.374 37.6068C139.354 37.5966 139.345 37.5786 139.341 37.5581C139.325 37.4876 139.317 37.4068 139.317 37.3312C139.317 36.7286 139.842 36.3657 140.397 36.3657C141.545 36.3657 142.246 37.3991 142.246 38.5389C142.246 39.5057 141.842 40.4044 141.22 41.1147C141.882 41.6788 142.571 42.2558 143.246 42.8071H140.355C140.234 42.7122 139.816 42.3776 139.695 42.2827C138.912 42.7392 137.979 43.0353 137.02 43.0353C135.36 43.0353 133.672 41.9609 133.672 40.1762C133.672 39.0492 134.252 38.1915 135.24 37.6799C134.833 37.1568 134.509 36.4734 134.509 35.8696C134.509 34.031 136.237 33.2002 137.83 33.2002C138.451 33.2002 139.073 33.2528 139.695 33.3335L139.491 34.8631C139.06 34.7285 138.601 34.6618 138.154 34.6618C137.331 34.6618 136.656 35.0913 136.656 35.9632C136.656 37.0901 139.06 39.1838 140.261 40.23C140.464 39.8685 140.572 39.4787 140.572 38.9967C140.572 38.244 140.207 37.5594 139.655 37.5594C139.592 37.5594 139.516 37.5773 139.451 37.6017Z"
            fill="#52565B"
          />
          <path
            d="M123.693 41.7914C123.716 41.7799 123.753 41.7722 123.778 41.7914C123.796 41.8043 123.81 41.8299 123.805 41.8684C123.728 42.4479 123.246 42.9146 122.448 42.9146C121.515 42.9146 121.004 42.312 121.004 41.3196V32.7461H123.001V41.2119C123.001 41.5607 123.11 41.8427 123.446 41.8427C123.542 41.8427 123.623 41.8248 123.693 41.7914Z"
            fill="#52565B"
          />
          <path
            d="M41.5105 11.8286C41.7763 11.7466 42.1131 11.7145 42.3918 11.7645C43.8821 12.0171 44.433 14.498 44.304 18.0225C44.1569 22.0779 42.5557 28.6987 39.5984 28.7308C39.3726 28.7321 39.0926 28.7308 38.8797 28.6551L35.9521 8.97206H30.5187L32.6735 21.8689C32.28 24.4536 31.1523 28.1949 28.8375 28.5846C28.6117 28.6218 28.0466 28.659 27.7447 28.5987L24.8274 8.97206H19.3915L23.3616 32.7374C24.697 33.0746 26.1641 33.0964 27.4053 32.8233C30.3794 32.1669 32.658 29.2834 33.3973 26.2037L34.4902 32.7387C35.7856 33.0284 36.5598 33.0259 37.6875 33.0079C40.6745 32.9566 43.0537 31.3284 44.6962 28.8513C47.2316 25.0216 48.287 19.5149 48.287 14.9929C48.287 11.2824 47.1555 8.50537 44.3453 8.50537C42.9453 8.50537 41.4228 9.65671 41.3015 11.6581C41.2964 11.7286 41.3118 11.7812 41.3596 11.8145C41.4022 11.844 41.4641 11.844 41.5105 11.8286ZM5.26172 19.2072C8.07451 18.9918 9.39445 18.4533 10.5196 17.7225C11.7802 16.902 12.3621 15.7801 12.415 14.7134C12.4769 13.4531 11.7221 12.135 9.46413 11.6196C8.27708 11.3491 6.69521 11.3594 5.26172 11.5607V19.2072ZM7.51324 29.9552C9.38284 29.9552 10.3751 29.5936 11.2357 29.1026C12.7801 28.2205 13.6627 26.4025 13.482 24.5101C13.3053 22.6664 12.0614 21.4804 10.2705 20.9701C8.95834 20.5996 7.1339 20.6034 5.26172 21.0971V29.7437C5.88105 29.8821 6.79327 29.9552 7.51324 29.9552ZM13.3207 18.8764C14.2162 19.0508 15.2226 19.3944 16.0342 19.8367C18.4934 21.1817 19.4147 23.3126 19.1424 25.855C18.8741 28.3308 17.198 30.4796 14.7478 31.6463C13.2369 32.363 11.4963 32.7771 9.42542 32.9297C6.50425 33.1426 3.13535 32.9502 0 32.3797V9.29387C2.54183 8.87975 5.39978 8.52076 7.9158 8.52332C10.6434 8.52588 12.513 8.7977 14.3504 9.65159C16.5258 10.6645 17.2883 12.3876 17.3077 13.8236C17.3257 15.2519 16.4703 17.5033 13.3207 18.8764Z"
            fill="#52565B"
          />
          <path
            d="M59.9294 0.300748C60.6352 8.83453 60.9384 15.3785 60.9384 20.8916C60.9384 26.4034 60.6184 32.9473 59.9294 41.4824C59.9152 41.6516 59.7733 41.7811 59.6017 41.7811C59.4314 41.7811 59.2894 41.6516 59.274 41.4824C58.5669 32.9345 58.2637 26.3893 58.2637 20.8916C58.2637 15.3938 58.5669 8.84991 59.274 0.300748C59.2894 0.131509 59.4314 0.000732422 59.6017 0.000732422C59.772 0.000732422 59.9152 0.131509 59.9294 0.300748Z"
            fill="#52565B"
          />
          <path
            d="M216.117 23.6841C216.052 23.6841 215.988 23.6892 215.932 23.6995V24.4841C215.997 24.488 216.07 24.4931 216.135 24.4931C216.404 24.4931 216.653 24.3739 216.653 24.0854C216.653 23.7905 216.4 23.6841 216.117 23.6841ZM216.196 24.7264C216.112 24.7405 216.013 24.7316 215.932 24.7264V25.7419H215.559V23.47C215.772 23.4559 215.969 23.4469 216.169 23.4469C216.56 23.4469 217.027 23.5546 217.027 24.0405C217.027 24.3546 216.801 24.5726 216.537 24.6662C216.805 25.1521 217.027 25.5021 217.202 25.5021C217.226 25.5021 217.249 25.5021 217.273 25.4893L217.297 25.506V25.5355C217.297 25.6342 217.211 25.7521 217.05 25.7521C216.649 25.7521 216.419 25.2585 216.196 24.7264ZM216.29 23.1097C215.497 23.1097 214.862 23.7508 214.862 24.6303C214.862 25.5149 215.486 26.1701 216.29 26.1701C217.084 26.1701 217.699 25.5111 217.699 24.6303C217.699 23.7674 217.068 23.1097 216.29 23.1097ZM216.29 26.3945C215.304 26.3945 214.57 25.6534 214.57 24.6303C214.57 23.6315 215.304 22.8687 216.29 22.8687C217.268 22.8687 217.995 23.6456 217.995 24.6303C217.995 25.6444 217.268 26.3945 216.29 26.3945Z"
            fill="#52565B"
          />
        </g>
        <defs>
          <clipPath id="clip0_690_230214">
            <rect width="218" height="43.0617" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ScrollLogo2;
