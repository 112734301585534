import React from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
  description: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: "Market Segment",
    dataIndex: "name",
    key: "name",
    align: "left",
    width: 200,
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">
        Brand Discount
      </p>
    ),
  },
  {
    width: 4,
    render: () => <p className="bg-white border-none">&nbsp;</p>,
  },
  {
    title: "Rooms",
    dataIndex: "age",
    key: "age",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">1</p>
    ),
  },
  {
    title: "LY",
    dataIndex: "address",
    key: "address",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">2</p>
    ),
  },
  {
    title: "Arr Date",
    dataIndex: "name",
    key: "name",
    align: "center",
    width: 250,
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">
        Sep 05, 2023
      </p>
    ),
  },
  {
    title: "LOS",
    dataIndex: "age",
    key: "age",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">12</p>
    ),
  },
  {
    title: "Pick From Sep 5, 2023",
    dataIndex: "address",
    key: "address",
    align: "center",
    width: 200,
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">6</p>
    ),
  },
  {
    title: "ADR Pick Up",
    dataIndex: "name",
    key: "name",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">$24</p>
    ),
  },
  {
    title: "ADR",
    dataIndex: "age",
    key: "age",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">$24</p>
    ),
  },
  {
    title: "LY",
    dataIndex: "address",
    key: "address",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">$LY</p>
    ),
  },
  {
    title: "Revenue",
    dataIndex: "age",
    key: "age",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">$24</p>
    ),
  },
  {
    title: "LY",
    dataIndex: "address",
    key: "address",
    align: "center",
    render: (records) => (
      <p className="font-roboto font-normal text-sm tracking-[0.5px]">$16</p>
    ),
  },
];

const data: DataType[] = [
  {
    key: 1,
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    description:
      "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
  },
  {
    key: 2,
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    description:
      "My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.",
  },
  {
    key: 3,
    name: "Not Expandable",
    age: 29,
    address: "Jiangsu No. 1 Lake Park",
    description: "This not expandable",
  },
  {
    key: 4,
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    description:
      "My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.",
  },
  {
    key: 1,
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    description:
      "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
  },
  {
    key: 2,
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    description:
      "My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.",
  },
  {
    key: 3,
    name: "Not Expandable",
    age: 29,
    address: "Jiangsu No. 1 Lake Park",
    description: "This not expandable",
  },
  {
    key: 4,
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    description:
      "My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.",
  },
  {
    key: 1,
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    description:
      "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
  },
  {
    key: 2,
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    description:
      "My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.",
  },
  {
    key: 3,
    name: "Not Expandable",
    age: 29,
    address: "Jiangsu No. 1 Lake Park",
    description: "This not expandable",
  },
  {
    key: 4,
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    description:
      "My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.",
  },
  {
    key: 1,
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    description:
      "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
  },
  {
    key: 2,
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    description:
      "My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.",
  },
  {
    key: 3,
    name: "Not Expandable",
    age: 29,
    address: "Jiangsu No. 1 Lake Park",
    description: "This not expandable",
  },
  {
    key: 4,
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    description:
      "My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.",
  },
  {
    key: 1,
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    description:
      "My name is John Brown, I am 32 years old, living in New York No. 1 Lake Park.",
  },
  {
    key: 2,
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    description:
      "My name is Jim Green, I am 42 years old, living in London No. 1 Lake Park.",
  },
  {
    key: 3,
    name: "Not Expandable",
    age: 29,
    address: "Jiangsu No. 1 Lake Park",
    description: "This not expandable",
  },
  {
    key: 4,
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    description:
      "My name is Joe Black, I am 32 years old, living in Sydney No. 1 Lake Park.",
  },
];

const App: React.FC = () => (
  <Table
    columns={columns}
    bordered
    pagination={false}
    scroll={{
      scrollToFirstRowOnChange: false,
      y: "65vh",
    }}
    expandable={{
      expandedRowRender: (record) => (
        <p style={{ margin: 0 }}>{record.description}</p>
      ),
      rowExpandable: (record) => record.name !== "Not Expandable",
    }}
    dataSource={data}
  />
);

export default App;
