import React from "react";

const ScrollLogo1 = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="196"
        height="33"
        viewBox="0 0 196 33"
        fill="none"
      >
        <g clip-path="url(#clip0_690_230120)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M44.0733 0.90332H39.4325V14.4279H22.6674V0.90332H18.0645V31.6463H22.6674V17.6007H39.4325V31.6463H44.0733V0.90332Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 31.6463H4.59457V0.90332H0V31.6463Z"
            fill="#52565B"
          />
          <mask
            id="mask0_690_230120"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="54"
            y="0"
            width="29"
            height="32"
          >
            <path d="M54.1936 0H82.0108V31.7648H54.1936V0Z" fill="black" />
          </mask>
          <g mask="url(#mask0_690_230120)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M77.3667 16.4592V28.3749C76.0668 29.2573 73.8842 29.9533 71.4222 29.9533C64.2251 29.9533 59.3019 23.591 59.3019 15.5574C59.3019 7.98736 63.6204 1.76483 70.8192 1.76483C75.788 1.76483 78.6213 4.08672 80.7106 6.68742H80.9429V1.34675C79.4566 0.975454 75.3234 -0.000244141 71.0049 -0.000244141C60.5562 -0.000244141 54.1938 7.61606 54.1938 15.9755C54.1938 25.1235 61.0668 31.7647 71.098 31.7647C76.0668 31.7647 79.9209 29.8611 82.0111 29.2573V16.4592H77.3667Z"
              fill="#52565B"
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M84.9434 2.6485C85.1509 2.6485 85.2593 2.55709 85.2593 2.3826V2.37453C85.2593 2.18337 85.1426 2.11642 84.9434 2.11642H84.7105V2.6485H84.9434ZM84.3115 1.80911H84.9517C85.3754 1.80911 85.6497 1.98361 85.6497 2.35759V2.3662C85.6497 2.64043 85.4918 2.78992 85.2757 2.8566L85.7575 3.62931H85.3424L84.9017 2.92301H84.7105V3.62931H84.3115V1.80911ZM86.456 2.73991C86.456 1.83412 85.8242 1.20256 84.9434 1.20256C84.0623 1.20256 83.4308 1.84245 83.4308 2.74852C83.4308 3.66265 84.0956 4.2778 84.9434 4.2778C85.7992 4.2778 86.456 3.64598 86.456 2.73991ZM83.0979 2.74852C83.0979 1.72604 83.9125 0.90332 84.9434 0.90332C85.9906 0.90332 86.7883 1.7177 86.7883 2.73991C86.7883 3.77906 85.9739 4.57704 84.9434 4.57704C83.9209 4.57704 83.0979 3.7874 83.0979 2.74852Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M98.4514 0.90332H99.942V6.08105H108.727V0.90332H110.218V12.3649H108.727V7.2834H99.942V12.3649H98.4514V0.90332Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M127.32 5.89909V5.77084C127.32 3.22204 125.124 1.13809 121.341 1.13809C117.526 1.13809 115.361 3.09379 115.361 5.80311V5.93135C115.361 8.68853 117.83 10.58 121.389 10.58C125.044 10.58 127.32 8.65626 127.32 5.89909ZM113.807 5.94722V5.81897C113.807 2.56494 116.772 0 121.341 0C125.909 0 128.875 2.56494 128.875 5.78697V5.91522C128.875 9.15339 125.909 11.7503 121.389 11.7503C116.772 11.7503 113.807 9.15339 113.807 5.94722Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M134.778 2.05727H130.065V0.90332H140.981V2.05727H136.268V12.3649H134.778V2.05727Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M143.614 0.90332H153.264V2.05727H145.104V5.92081H151.981V7.07504H145.104V11.2107H153.649V12.3649H143.614V0.90332Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M156.258 0.90332H157.749V11.2107H165.139V12.3649H156.258V0.90332Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M167.098 8.11128H168.556C168.797 9.522 169.871 10.612 172.933 10.612C175.386 10.612 176.764 9.77849 176.764 8.38391C176.764 7.03718 175.754 6.60458 172.612 6.2357C169.454 5.8671 167.691 5.11375 167.691 3.06179C167.691 1.29833 169.535 0 172.548 0C175.53 0 177.438 1.05797 177.742 3.15805H176.38C176.027 1.66721 174.776 1.13809 172.548 1.13809C170.128 1.13809 169.086 1.87558 169.086 3.0298C169.086 4.05578 169.839 4.64888 173.045 5.01749C176.412 5.40223 178.207 6.07546 178.207 8.33578C178.207 10.3235 176.251 11.7503 172.933 11.7503C169.086 11.7503 167.386 10.1312 167.098 8.11128Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M103.998 25.5616C105.905 25.5616 107.108 24.7762 107.108 23.3174V23.2534C107.108 21.6182 105.809 21.0089 103.998 21.0089H99.942V25.5616H103.998ZM98.4514 19.8708H103.902C106.45 19.8708 108.599 20.8167 108.599 23.2373V23.3013C108.599 25.2892 106.915 26.2829 105.312 26.5714L109.512 31.3324H107.717L103.613 26.6838H99.942V31.3324H98.4514V19.8708Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M112.903 19.8708H122.553V21.0251H114.393V24.8883H121.27V26.0426H114.393V30.1785H122.938V31.3324H112.903V19.8708Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M125.549 27.9821H127.008C127.248 29.3928 128.322 30.4828 131.384 30.4828C133.837 30.4828 135.215 29.6493 135.215 28.2545C135.215 26.908 134.205 26.4752 131.063 26.1066C127.906 25.7379 126.142 24.9846 126.142 22.9326C126.142 21.1692 127.986 19.8708 130.999 19.8708C133.981 19.8708 135.889 20.9288 136.193 23.0286H134.831C134.478 21.5381 133.227 21.0089 130.999 21.0089C128.579 21.0089 127.537 21.7464 127.537 22.9007C127.537 23.9264 128.29 24.5195 131.496 24.8883C134.863 25.2728 136.658 25.9463 136.658 28.2064C136.658 30.1943 134.702 31.6209 131.384 31.6209C127.537 31.6209 125.838 30.0018 125.549 27.9821Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M152.611 25.7699V25.6417C152.611 23.0929 150.415 21.0089 146.632 21.0089C142.817 21.0089 140.653 22.9646 140.653 25.6737V25.8019C140.653 28.5591 143.122 30.4508 146.68 30.4508C150.335 30.4508 152.611 28.5271 152.611 25.7699ZM139.098 25.8181V25.6898C139.098 22.4355 142.063 19.8708 146.632 19.8708C151.201 19.8708 154.167 22.4355 154.167 25.6578V25.7861C154.167 29.0242 151.201 31.6209 146.68 31.6209C142.063 31.6209 139.098 29.0242 139.098 25.8181Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M163.611 25.5616C165.519 25.5616 166.721 24.7762 166.721 23.3174V23.2534C166.721 21.6182 165.423 21.0089 163.611 21.0089H159.556V25.5616H163.611ZM158.065 19.8708H163.515C166.064 19.8708 168.212 20.8167 168.212 23.2373V23.3013C168.212 25.2892 166.529 26.2829 164.926 26.5714L169.125 31.3324H167.33L163.226 26.6838H159.556V31.3324H158.065V19.8708Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M175.422 21.0251H170.709V19.8708H181.626V21.0251H176.913V31.3324H175.422V21.0251Z"
            fill="#52565B"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M184.258 27.9821H185.717C185.957 29.3928 187.031 30.4828 190.093 30.4828C192.546 30.4828 193.924 29.6493 193.924 28.2545C193.924 26.908 192.914 26.4752 189.772 26.1066C186.614 25.7379 184.851 24.9846 184.851 22.9326C184.851 21.1692 186.695 19.8708 189.708 19.8708C192.69 19.8708 194.598 20.9288 194.902 23.0286H193.539C193.187 21.5381 191.936 21.0089 189.708 21.0089C187.288 21.0089 186.246 21.7464 186.246 22.9007C186.246 23.9264 186.999 24.5195 190.205 24.8883C193.572 25.2728 195.367 25.9463 195.367 28.2064C195.367 30.1943 193.411 31.6209 190.093 31.6209C186.246 31.6209 184.547 30.0018 184.258 27.9821Z"
            fill="#52565B"
          />
          <mask
            id="mask1_690_230120"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="185"
            y="0"
            width="12"
            height="12"
          >
            <path d="M185.158 0H196.026V11.7449H185.158V0Z" fill="black" />
          </mask>
          <g mask="url(#mask1_690_230120)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M189.332 4.94143C190.942 4.25153 191.544 3.5455 191.544 2.63083C191.544 1.7487 190.914 1.05853 189.808 1.05853C188.786 1.05853 187.973 1.63632 187.973 2.77548C187.973 3.64175 188.561 4.31579 189.332 4.94143ZM189.094 10.6375C190.438 10.6375 191.446 10.1079 192.132 9.36985L188.828 5.98434C187.469 6.48174 186.475 7.23589 186.475 8.59956C186.475 9.85138 187.399 10.6375 189.094 10.6375ZM192.805 10.06C191.964 11.0067 190.732 11.7447 189.066 11.7447C186.713 11.7447 185.158 10.6053 185.158 8.67995C185.158 6.85088 186.573 5.8561 188.029 5.31058C187.273 4.63654 186.769 3.83426 186.769 2.75935C186.769 0.994274 188.211 -0.000244141 189.836 -0.000244141C191.572 -0.000244141 192.735 1.12279 192.735 2.6631C192.735 3.80226 191.838 4.90944 190.088 5.59933L192.875 8.42319L194.681 5.71172H195.913L193.617 9.17734L196.026 11.6323H194.331L192.805 10.06Z"
              fill="#52565B"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_690_230120">
            <rect width="196" height="32.2634" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ScrollLogo1;
