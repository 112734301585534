import React, { useState, useEffect } from "react";
import {
  UserOutlined,
  CaretDownOutlined,
  DownOutlined,
  SearchOutlined,
  SettingOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import { useParams } from "react-router-dom";
import {
  Button,
  Dropdown,
  message,
  Space,
  Tooltip,
  Input,
  Select,
  Divider,
} from "antd";
import { getLandingPageData } from "../../redux/reducers/landingPageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/hooks";
import UpArrow from "../../assets/image/UpArrow";
import DownArrow from "../../assets/image/DownArrow";
import UserLogo from "../../assets/image/UserLogo";
import CaretDownArrow from "../../assets/image/CaretDownArrow";
import SettingLogo from "../../assets/image/SettingLogo";
// const { Divider, Input, Select, Space, Button } = antd;
import { getFilteredData } from "../../redux/reducers/landingPageSlice";
import { useNavigate } from "react-router-dom";
import { getSummaryPageData } from "../../redux/reducers/summaryPageSlice";

const { Search } = Input;
const SummaryHeader = () => {
  const logOutUrl = process.env.REACT_APP_AUTH0_LOGOUT_URL;
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth0();
  const { userName, landingPageData } = useSelector(
    (store: any) => store.landingPage
  );
  let token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { hotel_id } = useParams();
  const [open, setOpen] = useState(false);
  let date = new Date();
  var next7Day = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  let payload = {
    stayStartDate: dayjs(date).format("YYYY-MM-DD"),
    stayEndDate: dayjs(next7Day).format("YYYY-MM-DD"),
    loggedInUserEmail: user?.email,
    token,
  };

  let summaryPayload = {
    stayStartDate: dayjs(firstDay).format("YYYY-MM-DD"),
    stayEndDate: dayjs(lastDay).format("YYYY-MM-DD"),
    aticaHotelId: hotel_id,
    loggedInUserEmail: user?.email,
    token,
  };
  useEffect(() => {
    dispatch(getLandingPageData(payload));
    dispatch(getSummaryPageData(summaryPayload));
  }, [user, token]);
  let newArray: any = [];
  landingPageData?.map((item: any) => {
    newArray.push({
      label:
        item.brandHotelId + `${item.brandHotelId && " | "}` + item.hotelName,
      key: item.aticaHotelId,
    });
  });
  const handleMenuClick = (e: any) => {
    message.info("Click on menu item.");
    console.log("click", e);
  };
  const menuProps = {
    items: newArray,
    onClick: handleMenuClick,
  };
  const items = [
    {
      label: "Log Out",
      key: "1",
    },
  ];
  const onClick = ({ key }: any) => {
    message.success(`You have been logged out!`);
    logout({ logoutParams: { returnTo: logOutUrl } });
    localStorage.clear();
  };
  const onSearch = (e: any) => {
    e.preventDefault();
    console.log("Search", e.target.value);
    if (e.target.value) {
      dispatch(getFilteredData(e.target.value));
    } else {
      // setDropdownLabel(
      //   `Next 7 Days (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
      //     next7Day
      //   ).format("DD MMM YYYY")}`
      // );
      dispatch(getLandingPageData(payload));
    }
  };
  const onSelect = (e: any, value: any) => {
    console.log("valuee", e, value);
  };

  return (
    <div className="bg-[#004a98] shadow-lg flex flex-wrap justify-between items-center sticky z-50 py-2 px-6 h-20">
      <div className="flex flex-wrap items-center justify-evenly">
        <div>
          <img
            className="object-fill h-6 w-28"
            alt="LOGO"
            src="/aticalogowhitetexttransbg656x140-1@2x.png"
          />
        </div>
        <div className="ml-12 w-96 flex items-center">
          <div className="mr-6">
            <Select
              style={{
                width: 300,
              }}
              onSelect={handleMenuClick}
              onClick={() => setOpen(!open)}
              onBlur={() => setOpen(false)}
              suffixIcon={open ? <UpArrow /> : <DownArrow />}
              // placeholder="custom dropdown render"
              value={"GA1234 | Comfort Suites Grand Prairie"}
              dropdownRender={(menu) => (
                <>
                  <div
                    className="mb-3"
                    // style={{
                    //   padding: "0 8px 4px",
                    // }}
                  >
                    {/* <Input
                      placeholder="Search hotel"
                      // ref={inputRef}
                      // value={name}
                      // onChange={onNameChange}
                      prefix={<SearchOutlined />}
                      // onChange={onSearch}
                      allowClear
                    /> */}
                    <div className="relative  w-full">
                      <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                        <button type="submit" className="p-1">
                          <svg
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            viewBox="0 0 24 24"
                            className="w-6 h-6 text-[#8C8C8C]"
                          >
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </button>
                      </span>
                      <input
                        type="search"
                        // name="q"
                        className="py-2 text-sm font-roboto font-normal text-[#0E0E0E] rounded-md pl-10 w-full border border-gray-300"
                        placeholder="Search hotel"
                        // autocomplete="off"
                        onChange={onSearch}
                      />
                    </div>
                  </div>
                  {menu}
                  <Divider
                    style={{
                      margin: "8px 0",
                    }}
                  />
                  <div
                    className="flex flex-wrap items-center justify-center"
                    // style={{
                    //   padding: "0 8px 4px",
                    // }}
                  >
                    <p
                      className="text-center text-sky-800 text-sm font-normal leading-tight tracking-tight font-roboto cursor-pointer"
                      onClick={(e) => navigate("/dashboard")}
                    >
                      Back to all hotels
                    </p>
                  </div>
                </>
              )}
              options={newArray.map((item: any) => ({
                label: item.label,
                key: item.key,
                value: item.key,
              }))}
            />
            {/* <Dropdown menu={menuProps}>
              <Button
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
              >
                <Space>
                  GA1234 | Comfort Suites
                  {open ? <UpArrow /> : <DownArrow />}
                </Space>
              </Button>
            </Dropdown> */}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap items-center h-10">
        <div className="mr-6">
          <SettingLogo />
        </div>
        <div className="w-0.5 h-7 bg-white rounded-md mr-6" />
        <div className="flex items-center justify-around">
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <UserLogo />
                <p className="text-white text-base cursor-pointer mr-1 letter-spacing">
                  {userName}
                </p>
                <CaretDownArrow />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default SummaryHeader;
