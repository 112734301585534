import React from "react";
import PreLandingContent from "./PreLandingPageContent";
import CarouselComp from "./Carousel";
import PreLandingPageFooter from "./PreLandingPageFooter";

const PreLandingPageComp = () => {
  return (
    <>
      <PreLandingContent />
      <CarouselComp />
      <PreLandingPageFooter />
    </>
  );
};

export default PreLandingPageComp;
