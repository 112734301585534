import React from "react";
import TableWithFilter from "../Tables/TableWithFilter";
import { useSelector } from "react-redux";

const DashboardComp = () => {
  const { landingPageData, isLoading } = useSelector(
    (store: any) => store.landingPage
  );
  return (
    <div className="w-full my-6">
      <div className="my-6 px-6 shadow-sm">
        <TableWithFilter data={landingPageData} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default DashboardComp;
