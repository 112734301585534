import React, { useState, useEffect } from "react";
// import PreLandingPageIllustrator from "../../assets/image/PreLandingPageIllustrator";

const PreLandingContent = () => {
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    function handleWindowResize() {
      setHeight(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [height]);
  function getWindowSize() {
    const { innerHeight } = window;
    return innerHeight;
  }
  return (
    <>
      <div
        className="w-full flex h-2/3"
        // style={{ backgroundImage: "url(background.svg)" }}
      >
        <div
          className="flex flex-wrap w-full h-full bg-cover bg-center top-[21px] left-[-60px] mt-[-39px]"
          style={{ backgroundImage: "url(bg-image.svg)" }}
        >
          {/* <<--------------Content Text And Button Start------------->> */}
          <div className="pl-12 flex items-center justify-start">
            <div className="mt-[213px]">
              <h3 className="text-start text-sky-800 text-6xl font-bold font-montserrat mb-8">
                RevPilot
              </h3>
              <p className="w-[500px] text-neutral-700 text-3xl font-normal font-poppins leading-relaxed mb-8">
                Next-Generation Revenue{" "}
                <span>Management Platform by Atica</span>
              </p>
              {height > 500 ? (
                <a
                  href="https://www.aticaglobal.com/contact"
                  target="_blank"
                  className="w-fit h-16 px-8 py-4 bg-gradient-to-l from-[#004A98] to-[#003976] focus:from-[#0070E7] focus:to-[#0052A8] hover:from-[#0061C7] hover:to-[#004A98] rounded-[32px] justify-center items-center inline-flex text-white text-xl font-medium font-poppins"
                >
                  Request a Call
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <<--------------Content Text And Button End------------->> */}

          {/* <<--------------Illustration Start------------->> */}
          {/* <div className="flex items-center justify-center">
          <div className="mt-[70px]">
              <PreLandingPageIllustrator />
            </div>
          </div> */}
          {/* <<--------------Illustration End------------->> */}
        </div>
      </div>
    </>
  );
};

export default PreLandingContent;
