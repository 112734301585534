import React from "react";

const ScrollLogo3 = () => {
  return (
    <>
      <svg
        width="106"
        height="46"
        viewBox="0 0 106 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_788_236506)">
          <path
            d="M9.68844 1.57046H92.1083V30.3568H9.68844V1.57046ZM8.24609 31.8113H93.5749V0.128096H8.24609V31.8113Z"
            fill="#52565B"
          />
          <path
            d="M30.8167 8.61043V23.7005L32.0045 23.8581V24.7429H26.1502V23.8581L27.4108 23.7005V16.8282H19.9203V23.7005L21.0717 23.8581V24.7429H15.3145V23.8581L16.4659 23.7005V8.61043L15.3145 8.45286V7.59231H21.0717V8.45286L19.9203 8.61043V15.0586H27.4108V8.61043L26.1502 8.45286V7.59231H32.0045V8.45286L30.8167 8.61043Z"
            fill="#52565B"
          />
          <path
            d="M39.0347 23.8096L40.1376 23.9672V24.7429H34.7561V23.9672L35.8591 23.8096V13.4708L34.7561 13.3132V12.5375H39.0347V23.8096Z"
            fill="#52565B"
          />
          <path
            d="M42.4141 23.9793L43.4928 23.8339V8.50136L42.4141 8.34381V7.59231H46.6926V23.8339L47.7956 23.9793V24.7429H42.4141V23.9793Z"
            fill="#52565B"
          />
          <path
            d="M54.3488 12.3921H57.2335V13.5314H54.3488V20.7916C54.3488 22.6703 54.7366 23.2642 56.4456 23.2642C57.0759 23.2642 57.6335 23.2642 58.1546 23.1309V23.9672C56.6032 24.5126 55.2942 24.7429 54.1912 24.7429C51.7186 24.7429 51.149 23.6278 51.149 21.4946V13.5314H48.8945V12.3921H51.2338V9.84675L54.3609 8.91344V12.3921"
            fill="#52565B"
          />
          <path
            d="M71.9398 18.3917C71.9398 22.1976 69.3702 24.7429 65.3219 24.7429C61.407 24.7429 58.9102 23.0824 58.9102 18.3917C58.9102 14.4647 61.4797 11.8345 65.528 11.8345C69.5278 11.8345 71.9398 14.2101 71.9398 18.3917ZM68.4248 18.319C68.4248 14.4647 67.2733 12.7799 65.4068 12.7799C63.5887 12.7799 62.4372 14.392 62.4372 18.319C62.4372 22.1976 63.5887 23.8096 65.4068 23.8096C67.3461 23.8096 68.4248 22.2703 68.4248 18.319Z"
            fill="#52565B"
          />
          <path
            d="M86.3795 23.9672V24.7429H80.998V23.9672L82.0767 23.8096V16.8646C82.0767 14.9374 81.3616 14.0768 79.895 14.0768C79.2162 14.0768 78.1375 14.5495 77.3497 15.0949V23.8096L78.4527 23.9672V24.7429H73.0469V23.9672L74.1499 23.8096V13.5556L73.0469 13.3981V12.5375H77.3497V14.2344C78.6829 13.1678 79.9798 12.3072 81.7858 12.3072C84.1978 12.3072 85.2765 13.7132 85.2765 16.4767V23.8217L86.3795 23.9672Z"
            fill="#52565B"
          />
          <path
            d="M39.4001 8.88338C39.4001 9.82878 38.6244 10.6045 37.6668 10.6045C36.7093 10.6045 35.9336 9.8409 35.9336 8.88338C35.9336 7.93797 36.7093 7.16226 37.6668 7.16226C38.6244 7.16226 39.4001 7.93797 39.4001 8.88338Z"
            fill="#52565B"
          />
          <path
            d="M95.7164 1.2416H95.971C96.1406 1.2416 96.2013 1.1931 96.2013 1.0719C96.2013 0.950692 96.1406 0.902221 95.971 0.902221H95.7164V1.2416ZM96.4194 1.05979C96.4194 1.22948 96.3467 1.32643 96.2013 1.37491L96.4679 1.76276H96.2255L95.9831 1.41128H95.7164V1.76276H95.5104V0.720413H95.971C96.2861 0.720413 96.4194 0.829504 96.4194 1.05979ZM95.0862 1.25371C95.0862 1.72641 95.474 2.12638 95.9588 2.12638C96.4315 2.12638 96.8315 1.73853 96.8315 1.25371C96.8315 0.781004 96.4437 0.381031 95.9588 0.381031C95.474 0.381031 95.0862 0.768883 95.0862 1.25371ZM97.0497 1.25371C97.0497 1.85973 96.5527 2.35669 95.9467 2.35669C95.3407 2.35669 94.8438 1.85973 94.8438 1.25371C94.8438 0.647677 95.3407 0.150752 95.9467 0.150752C96.5649 0.150752 97.0497 0.635557 97.0497 1.25371Z"
            fill="#52565B"
          />
          <path
            d="M11.5287 45.3611H10.2318C10.0864 45.3611 10.0137 45.2884 10.0137 45.1429V38.0887C10.0137 37.9433 10.0864 37.8706 10.2318 37.8706H14.9104C15.0558 37.8706 15.1286 37.9433 15.1286 38.0887V39.2281C15.1286 39.3735 15.0558 39.4462 14.9104 39.4462H11.7469V41.2522H14.1347C14.2801 41.2522 14.3528 41.3249 14.3528 41.4704V42.5249C14.3528 42.6703 14.2801 42.743 14.1347 42.743H11.7469V45.1793C11.7469 45.3005 11.6742 45.3611 11.5287 45.3611Z"
            fill="#52565B"
          />
          <path
            d="M21.4393 43.4097C21.7666 43.2157 22.0211 42.9491 22.215 42.6097C22.409 42.2703 22.5059 41.8946 22.5059 41.4825C22.5059 41.0704 22.409 40.6947 22.215 40.3553C22.0211 40.0159 21.7545 39.7493 21.4272 39.5553C21.1 39.3614 20.7485 39.2644 20.3727 39.2644C19.997 39.2644 19.6334 39.3614 19.3182 39.5553C18.991 39.7493 18.7364 40.0159 18.5425 40.3553C18.3486 40.6947 18.2516 41.0704 18.2516 41.4825C18.2516 41.8946 18.3486 42.2703 18.5425 42.6097C18.7364 42.9491 19.0031 43.2157 19.3304 43.4097C19.6576 43.6036 20.0091 43.7006 20.3848 43.7006C20.7606 43.7006 21.1121 43.6036 21.4393 43.4097ZM18.4092 44.8399C17.8153 44.4884 17.3426 44.0278 17.0032 43.4339C16.6638 42.84 16.4941 42.1855 16.4941 41.4704C16.4941 40.7553 16.6638 40.1129 17.0032 39.519C17.3426 38.9251 17.8153 38.4645 18.4092 38.113C19.0031 37.7736 19.6576 37.6039 20.3848 37.6039C21.1 37.6039 21.7545 37.7736 22.3484 38.113C22.9423 38.4524 23.415 38.9251 23.7544 39.519C24.0937 40.1129 24.2634 40.7674 24.2634 41.4704C24.2634 42.1855 24.0937 42.8279 23.7544 43.4339C23.415 44.0278 22.9423 44.5005 22.3484 44.8399C21.7545 45.1914 21.1 45.3611 20.3848 45.3611C19.6576 45.349 19.0031 45.1793 18.4092 44.8399Z"
            fill="#52565B"
          />
          <path
            d="M29.4572 41.3113C29.6147 41.2144 29.7359 41.0931 29.8208 40.9356C29.9056 40.778 29.9541 40.6083 29.9541 40.4144C29.9541 40.2083 29.9056 40.0265 29.8208 39.869C29.7359 39.7235 29.6147 39.6023 29.4572 39.5296C29.3117 39.4569 29.142 39.4205 28.9481 39.4205H27.6391V41.4446H28.9239C29.1178 41.4446 29.2996 41.4083 29.4572 41.3113ZM25.918 45.1656V38.1115C25.918 37.966 25.9907 37.8933 26.1361 37.8933H29.0936C29.6026 37.8933 30.0632 38.0024 30.4511 38.2084C30.851 38.4266 31.1541 38.7175 31.3722 39.0932C31.5904 39.469 31.6995 39.9053 31.6995 40.4023C31.6995 40.8871 31.6025 41.2992 31.3965 41.6386C31.1904 41.9779 30.9116 42.2567 30.5359 42.4749L31.8449 45.1172C31.8692 45.1656 31.8813 45.202 31.8813 45.2263C31.8813 45.2747 31.8692 45.299 31.8328 45.3232C31.7964 45.3475 31.748 45.3596 31.6995 45.3596H30.2087C30.0874 45.3596 30.0026 45.3111 29.9541 45.202L28.8875 42.9112H27.6391V45.1414C27.6391 45.2869 27.5664 45.3596 27.4209 45.3596H26.124C25.9786 45.3717 25.918 45.3111 25.918 45.1656Z"
            fill="#52565B"
          />
          <path
            d="M40.1232 45.3611H38.8263C38.6809 45.3611 38.6082 45.2884 38.6082 45.1429V39.4826H36.7416C36.5962 39.4826 36.5234 39.4099 36.5234 39.2644V38.0766C36.5234 37.9312 36.5962 37.8584 36.7416 37.8584H42.208C42.3534 37.8584 42.4262 37.9312 42.4262 38.0766V39.2644C42.4262 39.4099 42.3534 39.4826 42.208 39.4826H40.3293V45.1429C40.3414 45.3005 40.2687 45.3611 40.1232 45.3611Z"
            fill="#52565B"
          />
          <path
            d="M46.2866 45.3611H44.9897C44.8442 45.3611 44.7715 45.2884 44.7715 45.1429V38.0887C44.7715 37.9433 44.8442 37.8706 44.9897 37.8706H46.2866C46.432 37.8706 46.5047 37.9433 46.5047 38.0887V40.6341H49.547V38.0887C49.547 37.9433 49.6197 37.8706 49.7652 37.8706H51.0742C51.2196 37.8706 51.2924 37.9433 51.2924 38.0887V45.1429C51.2924 45.2884 51.2196 45.3611 51.0742 45.3611H49.7652C49.6197 45.3611 49.547 45.2884 49.547 45.1429V42.2825H46.5047V45.1429C46.4926 45.3005 46.432 45.3611 46.2866 45.3611Z"
            fill="#52565B"
          />
          <path
            d="M58.8779 45.3611H53.8236C53.6782 45.3611 53.6055 45.2884 53.6055 45.1429V38.0887C53.6055 37.9433 53.6782 37.8706 53.8236 37.8706H58.8052C58.9506 37.8706 59.0234 37.9433 59.0234 38.0887V39.2402C59.0234 39.3856 58.9506 39.4584 58.8052 39.4584H55.3387V40.8037H58.0052C58.1507 40.8037 58.2234 40.8765 58.2234 41.0219V42.0764C58.2234 42.2218 58.1507 42.2946 58.0052 42.2946H55.3387V43.8096H58.8779C59.0234 43.8096 59.0961 43.8824 59.0961 44.0278V45.1793C59.084 45.3005 59.0112 45.3611 58.8779 45.3611Z"
            fill="#52565B"
          />
          <path
            d="M66.1704 45.0338C65.6735 44.8156 65.2371 44.5005 64.8614 44.0884C64.8129 44.0399 64.8008 43.9915 64.8008 43.943C64.8008 43.8945 64.825 43.8339 64.8856 43.7854L65.7098 42.9976C65.7583 42.9491 65.8068 42.9249 65.8553 42.9249C65.9159 42.9249 65.9644 42.9491 66.0128 43.0097C66.2552 43.2521 66.5219 43.446 66.8249 43.5672C67.1279 43.6884 67.4431 43.7612 67.7582 43.7612C68.0976 43.7612 68.3642 43.7006 68.546 43.5672C68.7278 43.446 68.8248 43.2642 68.8248 43.0461C68.8248 42.9006 68.7884 42.7673 68.7036 42.6703C68.6188 42.5612 68.4733 42.4643 68.2673 42.3794C68.0612 42.2825 67.7703 42.1855 67.3946 42.0764C66.7764 41.8946 66.2431 41.6279 65.7825 41.2643C65.3341 40.9007 65.1038 40.4038 65.1038 39.7493C65.1038 39.3614 65.2008 39.0099 65.4068 38.6827C65.6007 38.3554 65.9037 38.1009 66.3037 37.9069C66.7037 37.713 67.1885 37.616 67.7582 37.616C68.243 37.616 68.7036 37.7009 69.1521 37.8706C69.5884 38.0403 69.9884 38.2706 70.3399 38.5736C70.3884 38.622 70.4247 38.6705 70.4247 38.719C70.4247 38.7675 70.4005 38.816 70.3641 38.8645L69.6126 39.7129C69.5642 39.7735 69.5157 39.7977 69.4551 39.7977C69.4066 39.7977 69.3581 39.7735 69.3096 39.7371C69.1036 39.5796 68.8733 39.4462 68.6066 39.3372C68.34 39.2281 68.0855 39.1796 67.8309 39.1796C67.54 39.1796 67.3097 39.2281 67.1522 39.325C66.9825 39.422 66.9098 39.5674 66.9098 39.7493C66.9098 39.8947 66.9582 40.0159 67.0552 40.125C67.1522 40.222 67.2734 40.3068 67.4309 40.3795C67.5885 40.4523 67.8309 40.5371 68.1461 40.6341C68.1582 40.6341 68.2067 40.6462 68.2794 40.6825C68.7884 40.8522 69.2127 41.034 69.552 41.228C69.8914 41.4219 70.1459 41.6643 70.3399 41.9552C70.5338 42.2461 70.6187 42.5976 70.6187 43.0097C70.6187 43.4824 70.4974 43.8945 70.2672 44.246C70.0369 44.5975 69.7096 44.8763 69.2975 45.0702C68.8733 45.2641 68.4006 45.3611 67.843 45.3611C67.2128 45.3611 66.6673 45.252 66.1704 45.0338Z"
            fill="#52565B"
          />
          <path
            d="M75.469 45.3611H74.172C74.0266 45.3611 73.9539 45.2884 73.9539 45.1429V39.4826H72.0873C71.9419 39.4826 71.8691 39.4099 71.8691 39.2644V38.0766C71.8691 37.9312 71.9419 37.8584 72.0873 37.8584H77.5537C77.6991 37.8584 77.7719 37.9312 77.7719 38.0766V39.2644C77.7719 39.4099 77.6991 39.4826 77.5537 39.4826H75.675V45.1429C75.6871 45.3005 75.6144 45.3611 75.469 45.3611Z"
            fill="#52565B"
          />
          <path
            d="M82.8585 42.634L82.01 40.2341L81.9252 39.9311L81.8525 40.2341L81.004 42.634H82.8585ZM85.5007 45.2156C85.5007 45.2641 85.4886 45.3005 85.4523 45.3247C85.4159 45.349 85.3795 45.3611 85.3189 45.3611H83.9857C83.8645 45.3611 83.7796 45.3005 83.7433 45.1793L83.3433 44.0763H80.495L80.1071 45.1793C80.0707 45.3005 79.9859 45.3611 79.8647 45.3611H78.5314C78.4708 45.3611 78.4345 45.349 78.3981 45.3247C78.3617 45.3005 78.3496 45.2641 78.3496 45.2156C78.3496 45.1914 78.3496 45.155 78.3617 45.1308L81.1131 38.0524C81.1616 37.9433 81.2343 37.8827 81.3555 37.8827H82.507C82.6282 37.8827 82.713 37.9433 82.7615 38.0524L85.4886 45.1308C85.5007 45.155 85.5007 45.1793 85.5007 45.2156Z"
            fill="#52565B"
          />
          <path
            d="M89.444 45.3611H88.1349C87.9895 45.3611 87.9168 45.2884 87.9168 45.1429V42.44L85.4563 38.1251C85.432 38.0766 85.4199 38.0403 85.4199 38.0039C85.4199 37.9554 85.432 37.9312 85.4684 37.9069C85.5048 37.8827 85.5411 37.8706 85.6017 37.8706H87.141C87.2623 37.8706 87.3471 37.9191 87.3956 38.0281L88.8016 40.828L90.1712 38.0281C90.2197 37.9191 90.3045 37.8706 90.4257 37.8706H91.965C92.0256 37.8706 92.062 37.8827 92.0984 37.9069C92.1347 37.9312 92.1468 37.9675 92.1468 38.0039C92.1468 38.0403 92.1347 38.0766 92.1105 38.1251L89.6621 42.4279V45.1429C89.6621 45.3005 89.5894 45.3611 89.444 45.3611Z"
            fill="#52565B"
          />
          <path
            d="M93.1672 37.9168V37.7728H94.1727V37.9168H93.7511V39.1135H93.5888V37.9168H93.1672Z"
            fill="#52565B"
          />
          <path
            d="M94.418 37.7728H94.6118L95.0674 38.8857H95.0831L95.5388 37.7728H95.7325V39.1135H95.5807V38.0949H95.5676L95.1486 39.1135H95.0019L94.5829 38.0949H94.5699V39.1135H94.418V37.7728Z"
            fill="#52565B"
          />
        </g>
        <defs>
          <clipPath id="clip0_788_236506">
            <rect width="105.449" height="45.4523" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ScrollLogo3;
