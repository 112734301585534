import React, { useState, useEffect } from "react";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  message,
  Space,
  Tooltip,
  Input,
  Tabs,
  ConfigProvider,
} from "antd";
import axios from "axios";
import DailySummaryTable from "./DailySummaryTable";
import SummaryTable from "./SummaryPopUpTable";
import { useSelector } from "react-redux";

const SummaryComp = () => {
  const {
    summaryPageData,
    isLoading,
    hotelName,
    summaryDataUpdated,
    reservationDataUpdated,
  } = useSelector((store: any) => store.summaryPage);
  console.log("SummaryData", summaryPageData);
  const onChange = (key: any) => {
    console.log(key);
  };

  const tabList = [
    {
      key: "1",
      label: "Daily Summary",
      children: (
        <div className="border rounded-md p-4 h-full w-full">
          <DailySummaryTable
            summaryData={summaryPageData.info}
            isLoading={isLoading}
            hotelName={hotelName}
          />
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: "Monthly Summary",
    //   children: "Monthly Summary",
    // },
    // {
    //   key: "3",
    //   label: "Booking Pace",
    //   children: "Content of Tab 3",
    // },
    // {
    //   key: "4",
    //   label: "Heatmap",
    //   children: "Content of Tab 4",
    // },
    // {
    //   key: "5",
    //   label: "Reservation Activity",
    //   children: "Content of Tab 5",
    // },
  ];
  return (
    <div className="px-8 py-9">
      <div className="flex flex-wrap items-end justify-end">
        <p className="flex items-center text-xs font-roboto tracking-[0.5px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <circle cx="6" cy="6" r="6" fill="#439C50" />
          </svg>
          &nbsp; Summary data as of{" "}
          {summaryDataUpdated ? summaryDataUpdated : "- Not available"}
        </p>
        <p className="flex items-center text-xs font-roboto ml-4 tracking-[0.5px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <circle cx="6" cy="6" r="6" fill="#C30000" />
          </svg>
          &nbsp; Reservation data as of{" "}
          {reservationDataUpdated ? reservationDataUpdated : "- Not available"}
        </p>
      </div>
      <ConfigProvider
        componentSize="large"
        theme={{
          token: {
            fontFamily: "Roboto",
            colorPrimary: "#003B7A",
          },
        }}
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          items={tabList}
          onChange={onChange}
          className="w-full"
        />
      </ConfigProvider>
    </div>
  );
};
export default SummaryComp;
