import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { LandingPagePayload } from "../../types";
import axios from "axios";
let token = localStorage.getItem("token");
const baseURL = process.env.REACT_APP_BASE_URL;
const XToken = process.env.REACT_APP_X_TOKEN;

const initialState = {
  landingPageData: [],
  isLoading: true,
  error: null,
  userName: "",
};
export const getLandingPageData = createAsyncThunk(
  "landingPage/getLandingPageData",
  async (payload: LandingPagePayload) => {
    return axios.post(
      `${baseURL}/api/v1/rev-pilot/user-hotels-performance-metrics`,
      {
        stayStartDate: payload.stayStartDate,
        stayEndDate: payload.stayEndDate,
        loggedInUserEmail: payload.loggedInUserEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
          "X-Token": XToken,
          "Content-Type": "application/json",
        },
      }
    );
  }
);

const landingPageSlice = createSlice({
  name: "landingPage",
  initialState,
  reducers: {
    getFilteredData: (state, action) => {
      state.landingPageData = state.landingPageData.filter(
        (item: any) =>
          (item.hotelName &&
            item.hotelName.toLowerCase().includes(action.payload)) ||
          (item.brandHotelId &&
            item.brandHotelId.toLowerCase().startsWith(action.payload)) ||
          (item.aticaHotelId &&
            item.aticaHotelId.toLowerCase().startsWith(action.payload)) ||
          (item.ownerName &&
            item.ownerName.toLowerCase().includes(action.payload)) ||
          (item.rmName &&
            item.rmName.toLowerCase().startsWith(action.payload)) ||
          (item.raName &&
            item.raName.toLowerCase().startsWith(action.payload)) ||
          (item.smName && item.smName.toLowerCase().startsWith(action.payload))
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLandingPageData.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(getLandingPageData.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.landingPageData =
        action.payload?.data?.info?.hotelPerformanceMetrics;
      state.userName = action.payload?.data?.info?.fullName;
    });
    builder.addCase(getLandingPageData.rejected, (state: any) => {
      state.isLoading = false;
    });
  },
});

export const { getFilteredData } = landingPageSlice.actions;
export default landingPageSlice.reducer;
