import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Dropdown, message, Space, Input } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getLandingPageData } from "../../redux/reducers/landingPageSlice";
import { getFilteredData } from "../../redux/reducers/landingPageSlice";
import { useAppDispatch } from "../../redux/hooks/hooks";
import UpArrow from "../../assets/image/UpArrow";
import DownArrow from "../../assets/image/DownArrow";
import UserLogo from "../../assets/image/UserLogo";
import CaretDownArrow from "../../assets/image/CaretDownArrow";

const Header = () => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const logOutUrl = process.env.REACT_APP_AUTH0_LOGOUT_URL;
  let token = localStorage.getItem("token");
  const [dropdownLabel, setDropdownLabel] = useState<string>();
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { userName } = useSelector((store: any) => store.landingPage);
  let date = new Date();
  var month = date.getMonth();
  var yesterday = new Date(date.getTime() - 1 * 24 * 60 * 60 * 1000);
  var tomorrow = new Date(date.getTime() + 1 * 24 * 60 * 60 * 1000);
  var last7Day = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
  var next7Day = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  var lastTwoWeeks = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
  var nextTwoWeeks = new Date(date.getTime() + 14 * 24 * 60 * 60 * 1000);
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var prevMonthLastDate = new Date(date.getFullYear(), date.getMonth(), 0);
  var prevMonthFirstDate = new Date(
    date.getFullYear() - (date.getMonth() > 0 ? 0 : 1),
    (date.getMonth() - 1 + 12) % 12,
    1
  );
  var nextMonthLastDate = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  var nextMonthFirstDate = new Date(
    date.getFullYear() + (date.getMonth() > 0 ? 0 : 2),
    (date.getMonth() + 1 + 12) % 12,
    1
  );

  const generateAccessToken = async () => {
    let accessToken = "";
    if (isAuthenticated) {
      accessToken = await getAccessTokenSilently({
        authorizationParams: {
          domain: domain,
          audience: audience,
          scope: "read:current_user",
        },
      });
      localStorage.setItem("token", accessToken);
    }
  };
  let payload = {
    stayStartDate: dayjs(date).format("YYYY-MM-DD"),
    stayEndDate: dayjs(next7Day).format("YYYY-MM-DD"),
    loggedInUserEmail: user?.email,
    token,
  };
  useEffect(() => {
    generateAccessToken();
    setDropdownLabel(
      `Next 7 Days (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
        next7Day
      ).format("DD MMM YYYY")}`
    );
    if (token) {
      dispatch(getLandingPageData(payload));
    }
  }, [user, token]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(getLandingPageData(payload));
  //   }, 50);
  // }, [token]);
  const menuitems = [
    {
      label: `This Month (${dayjs(date).format("MMM YYYY")})`,
      key: "1",
    },
    {
      label: `Last 2 Weeks (${dayjs(lastTwoWeeks).format(
        "DD MMM YYYY"
      )} - ${dayjs(date).format("DD MMM YYYY")})`,
      key: "2",
    },
    {
      label: `Last 7 Days (${dayjs(last7Day).format("DD MMM YYYY")} - ${dayjs(
        date
      ).format("DD MMM YYYY")})`,
      key: "3",
    },
    {
      label: `Yesterday (${dayjs(yesterday).format("DD MMM YYYY")})`,
      key: "4",
    },
    {
      label: `Next 7 Days (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
        next7Day
      ).format("DD MMM YYYY")}`,
      key: "5",
    },
    {
      label: `Today (${dayjs(date).format("DD MMM YYYY")})`,
      key: "6",
    },
    {
      label: `Tomorrow (${dayjs(tomorrow).format("DD MMM YYYY")})`,
      key: "7",
    },
    {
      label: `Next 2 Weeks (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
        nextTwoWeeks
      ).format("DD MMM YYYY")})`,
      key: "8",
    },
    {
      label: `Last Month (${dayjs(prevMonthFirstDate).format("MMM YYYY")})`,
      key: "9",
    },
    {
      label: `Next Month (${dayjs(nextMonthFirstDate).format("MMM YYYY")})`,
      key: "10",
    },
    {
      label: `Month Till Date (${dayjs(firstDay).format(
        "DD MMM YYYY"
      )} - ${dayjs(date).format("DD MMM YYYY")} )`,
      key: "11",
    },
  ];
  const items = [
    {
      label: "Log Out",
      key: "1",
    },
  ];
  const handleMenuClick = ({ key }: any) => {
    if (key == 1) {
      setDropdownLabel(`This Month (${dayjs(date).format("MMM YYYY")})`);
      let payload = {
        stayStartDate: dayjs(firstDay).format("YYYY-MM-DD"),
        stayEndDate: dayjs(lastDay).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 2) {
      setDropdownLabel(
        `Last 2 Weeks (${dayjs(lastTwoWeeks).format("DD MMM YYYY")} - ${dayjs(
          date
        ).format("DD MMM YYYY")})`
      );
      let payload = {
        stayStartDate: dayjs(lastTwoWeeks).format("YYYY-MM-DD"),
        stayEndDate: dayjs(date).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 3) {
      setDropdownLabel(
        `Last 7 Days (${dayjs(last7Day).format("DD MMM YYYY")} - ${dayjs(
          date
        ).format("DD MMM YYYY")})`
      );
      let payload = {
        stayStartDate: dayjs(last7Day).format("YYYY-MM-DD"),
        stayEndDate: dayjs(date).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 4) {
      setDropdownLabel(`Yesterday (${dayjs(yesterday).format("DD MMM YYYY")})`);
      let payload = {
        stayStartDate: dayjs(yesterday).format("YYYY-MM-DD"),
        stayEndDate: dayjs(yesterday).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 5) {
      setDropdownLabel(
        `Next 7 Days (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
          next7Day
        ).format("DD MMM YYYY")}`
      );
      let payload = {
        stayStartDate: dayjs(date).format("YYYY-MM-DD"),
        stayEndDate: dayjs(next7Day).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 6) {
      setDropdownLabel(`Today (${dayjs(date).format("DD MMM YYYY")})`);
      let payload = {
        stayStartDate: dayjs(date).format("YYYY-MM-DD"),
        stayEndDate: dayjs(date).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 7) {
      setDropdownLabel(`Tomorrow (${dayjs(tomorrow).format("DD MMM YYYY")})`);
      let payload = {
        stayStartDate: dayjs(tomorrow).format("YYYY-MM-DD"),
        stayEndDate: dayjs(tomorrow).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 8) {
      setDropdownLabel(
        `Next 2 Weeks (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
          nextTwoWeeks
        ).format("DD MMM YYYY")})`
      );
      let payload = {
        stayStartDate: dayjs(date).format("YYYY-MM-DD"),
        stayEndDate: dayjs(nextTwoWeeks).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 9) {
      setDropdownLabel(
        `Last Month (${dayjs(prevMonthFirstDate).format("MMM YYYY")})`
      );
      let payload = {
        stayStartDate: dayjs(prevMonthFirstDate).format("YYYY-MM-DD"),
        stayEndDate: dayjs(prevMonthLastDate).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 10) {
      setDropdownLabel(
        `Next Month (${dayjs(nextMonthFirstDate).format("MMM YYYY")})`
      );
      let payload = {
        stayStartDate: dayjs(nextMonthFirstDate).format("YYYY-MM-DD"),
        stayEndDate: dayjs(nextMonthLastDate).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
    if (key == 11) {
      setDropdownLabel(
        `Month Till Date (${dayjs(firstDay).format("DD MMM YYYY")} - ${dayjs(
          date
        ).format("DD MMM YYYY")} )`
      );
      let payload = {
        stayStartDate: dayjs(firstDay).format("YYYY-MM-DD"),
        stayEndDate: dayjs(date).format("YYYY-MM-DD"),
        loggedInUserEmail: user?.email,
        token,
      };
      dispatch(getLandingPageData(payload));
    }
  };
  const menuProps = {
    items: menuitems,
    onClick: handleMenuClick,
  };
  const onSearch = (e: any) => {
    e.preventDefault();
    console.log("Search", e.target.value);
    if (e.target.value) {
      dispatch(getFilteredData(e.target.value));
    } else {
      setDropdownLabel(
        `Next 7 Days (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
          next7Day
        ).format("DD MMM YYYY")}`
      );
      dispatch(getLandingPageData(payload));
    }
  };
  const onClick = ({ key }: any) => {
    message.success(`You have been logged out!`);
    logout({ logoutParams: { returnTo: logOutUrl } });
    localStorage.clear();
  };

  const onFocusOut = () => {
    setDropdownLabel(
      `Next 7 Days (${dayjs(date).format("DD MMM YYYY")} - ${dayjs(
        next7Day
      ).format("DD MMM YYYY")}`
    );
    dispatch(getLandingPageData(payload));
  };
  return (
    <div className="bg-[#004a98] shadow-lg flex flex-wrap justify-between items-center sticky z-50 py-2 px-6 h-20">
      <div className="flex flex-wrap items-center justify-evenly">
        <div>
          <img
            className="object-fill h-6 w-28"
            alt="LOGO"
            src="/aticalogowhitetexttransbg656x140-1@2x.png"
          />
        </div>
        <div className="ml-12 w-96">
          <Input
            placeholder="Search Hotel"
            prefix={<SearchOutlined />}
            onChange={onSearch}
            allowClear
            onFocus={onFocusOut}
          />
        </div>
      </div>
      <div className="flex flex-wrap items-center h-10">
        <div className="mr-6">
          <Dropdown menu={menuProps} trigger={["click"]}>
            <Button onClick={() => setOpen(true)} onBlur={() => setOpen(false)}>
              <Space>
                {dropdownLabel}
                {open ? <UpArrow /> : <DownArrow />}
              </Space>
            </Button>
          </Dropdown>
        </div>

        <div className="flex items-center justify-around">
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <UserLogo />
                <p className="text-white text-base cursor-pointer mr-1 letter-spacing">
                  {userName}
                </p>
                <CaretDownArrow />
              </Space>
            </a>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Header;
