import React from "react";
import { Carousel } from "antd";
import ScrollLogo1 from "../../assets/image/ScrollLogo1";
import ScrollLogo2 from "../../assets/image/ScrollLogo2";
import ScrollLogo3 from "../../assets/image/ScrollLogo3";
import ScrollLogo4 from "../../assets/image/ScrollLogo4";
import ScrollLogo5 from "../../assets/image/ScrollLogo5";
import ScrollLogo6 from "../../assets/image/ScrollLogo6";
import ScrollLogo7 from "../../assets/image/ScrollLogo7";
import ScrollLogo8 from "../../assets/image/ScrollLogo8";

const contentStyle: React.CSSProperties = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#FAFBFC",
  display: "flex",
  //   background: "black",
};
var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const CarouselComp: React.FC = () => (
  <div className="bg-[#FAFBFC] py-2 px-12">
    <h2 className="text-start text-sky-900 text-xl font-medium font-poppins mb-3">
      Trusted by hundreds of hoteliers for the brands
    </h2>
    <Carousel autoplay slidesToShow={5}>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo1 />
        {/* <h3 style={contentStyle}>1</h3> */}
      </div>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo2 />
      </div>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo3 />
      </div>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo4 />
      </div>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo5 />
      </div>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo6 />
      </div>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo7 />
      </div>
      <div className="flex items-center h-32 py-4 justify-center">
        <ScrollLogo8 />
      </div>
    </Carousel>
  </div>
);

export default CarouselComp;
