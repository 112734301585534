import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { SummaryPagePayload, SummaryNotesPayload } from "../../types";
import axios from "axios";
let token = localStorage.getItem("token");
const baseURL = process.env.REACT_APP_BASE_URL;
const XToken = process.env.REACT_APP_X_TOKEN;

const initialState = {
  summaryPageData: [],
  hotelName: "",
  isLoading: true,
  error: false,
  summaryDataUpdated: "",
  reservationDataUpdated: "",
  compSetData: "",
};
export const getSummaryPageData = createAsyncThunk(
  "summaryPage/getSummaryPageData",
  async (payload: SummaryPagePayload) => {
    return axios.post(
      `${baseURL}/api/v1/rev-pilot/daily-summary`,
      {
        stayStartDate: payload.stayStartDate,
        stayEndDate: payload.stayEndDate,
        aticaHotelId: payload.aticaHotelId,
        loggedInUserEmail: payload.loggedInUserEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
          "X-Token": XToken,
          "Content-Type": "application/json",
        },
      }
    );
  }
);
export const addNotes = createAsyncThunk(
  "summaryPage/addNotes",
  async (payload: SummaryNotesPayload) => {
    return axios.post(
      `https://test-api.aticastays.com/api/v1/rev-pilot/updateNotes`,
      {
        aticaHotelId: payload.aticaHotelId,
        stayStartDate: payload.stayStartDate,
        stayEndDate: payload.stayEndDate,
        rmNotes: payload.rmNotes,
        hotelNotes: payload.hotelNotes,
      },
      {
        headers: {
          Authorization: `Bearer ${payload.token}`,
          "X-Token": "2c9331fd-14d0-4ace-a848-5297e3d46851",
          "Content-Type": "application/json",
        },
      }
    );
  }
);
const summaryPageSlice = createSlice({
  name: "summaryPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSummaryPageData.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(getSummaryPageData.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.summaryPageData = action.payload?.data;
      state.summaryDataUpdated =
        action.payload?.data?.info?.occupancyDataUpdatedAt;
      state.reservationDataUpdated =
        action.payload?.data?.info?.reservationDataUpdatedAt;
      state.hotelName = action.payload?.data?.info?.propertyName;
      state.compSetData = action.payload?.data?.info?.compSetNames;
    });
    builder.addCase(getSummaryPageData.rejected, (state: any) => {
      state.isLoading = false;
    });
    builder.addCase(addNotes.pending, (state: any) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(addNotes.fulfilled, (state: any, action: any) => {
      state.isLoading = false;
      state.error = false;
    });
    builder.addCase(addNotes.rejected, (state: any) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export default summaryPageSlice.reducer;
