import React from "react";
import SummaryHeader from "../../components/common/SummaryHeader";
import SummaryComp from "../../components/Summary/SummaryComp";
const Summary = () => {
  return (
    <div className="w-full h-screen bg-white">
      <SummaryHeader />
      <SummaryComp />
    </div>
  );
};

export default Summary;
