import { configureStore } from "@reduxjs/toolkit";
import landingPageReducer from "./reducers/landingPageSlice";
import summaryPageReducer from "./reducers/summaryPageSlice";

export const store = configureStore({
  reducer: {
    landingPage: landingPageReducer,
    summaryPage: summaryPageReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
