import React, { useState, useEffect } from "react";
import Logo from "../../assets/image/Logo";
import { useAuth0 } from "@auth0/auth0-react";

const PreLandingPageHeader = () => {
  const [height, setHeight] = useState(getWindowSize());
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    function handleWindowResize() {
      setHeight(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [height]);
  function getWindowSize() {
    const { innerHeight } = window;
    return innerHeight;
  }
  const login = async () => {
    await loginWithRedirect();
  };
  return (
    <>
      <div className="w-full h-[60px] bg-white flex flex-wrap items-center justify-between px-12 z-55 sticky top-0 shadow-md">
        <div className="cursor-pointer">
          <Logo />
        </div>
        <div className="flex flex-wrap items-center justify-between">
          {/* <button className="text-center text-neutral-700 text-base font-normal mr-4 font-poppins cursor-pointer hover:text-[#1C1C1C]">
            Help
          </button> */}
          <button
            className="text-center text-sky-800 text-base font-medium font-poppins cursor-pointer mr-4 hover:text-[#003B7A]"
            onClick={login}
          >
            Sign In
          </button>
          {height < 500 ? (
            <a
              href="https://www.aticaglobal.com/contact"
              target="_blank"
              className="w-44 h-10 px-3 py-2 bg-gradient-to-l from-[#004A98] to-[#003976] focus:from-[#0070E7] focus:to-[#0052A8] hover:from-[#0061C7] hover:to-[#004A98] rounded-[32px] justify-center items-center inline-flex text-white text-base font-medium font-poppins"
            >
              Request a Call
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default PreLandingPageHeader;
