import React from "react";

const DownArrow = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20.0306 9.53068L12.5306 17.0307C12.461 17.1004 12.3782 17.1557 12.2872 17.1935C12.1961 17.2312 12.0986 17.2506 12 17.2506C11.9014 17.2506 11.8038 17.2312 11.7128 17.1935C11.6217 17.1557 11.539 17.1004 11.4694 17.0307L3.96936 9.53068C3.82863 9.38995 3.74957 9.19907 3.74957 9.00005C3.74957 8.80103 3.82863 8.61016 3.96936 8.46943C4.1101 8.32869 4.30097 8.24963 4.49999 8.24963C4.69901 8.24963 4.88988 8.32869 5.03061 8.46943L12 15.4397L18.9694 8.46943C19.039 8.39974 19.1218 8.34447 19.2128 8.30676C19.3039 8.26904 19.4014 8.24963 19.5 8.24963C19.5985 8.24963 19.6961 8.26904 19.7872 8.30676C19.8782 8.34447 19.9609 8.39974 20.0306 8.46943C20.1003 8.53911 20.1556 8.62183 20.1933 8.71288C20.231 8.80392 20.2504 8.9015 20.2504 9.00005C20.2504 9.0986 20.231 9.19618 20.1933 9.28722C20.1556 9.37827 20.1003 9.46099 20.0306 9.53068Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default DownArrow;
